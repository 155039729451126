import React from 'react';
import DashboardImg from '../../assets/images/dashboard.png';
import Users_groups from '../../assets/images/users_groups.png'
import System_Icon from '../../assets/images/system.png'
import Support_Icon from '../../assets/images/support.png'
import Application from '../../assets/images/application.png'
import Reports from '../../assets/images/reports.png'
import Active_Dashboard from '../../assets/images/active_dashboard.png';
import Active_Users_Groups from '../../assets/images/active_users_group.png';
import Active_Application_icon from '../../assets/svg/actice_application_icon.svg';
import Active_System_icon from '../../assets/svg/active_system_icon.svg';

import Dashboard from "../../HomeLayout/Component/Dashboard";
import Users from "../../HomeLayout/Component/Users";
import Groups from "../../HomeLayout/Component/Groups/Groups";
import System from "../../HomeLayout/Component/System";
import Support from "../../HomeLayout/Component/Support";
import ServerConfiguration from "../../HomeLayout/Component/ServerConfiguration";
import ApplicationManagement from "../../HomeLayout/Component/ApplicationManagement";
import Mustering from '../../HomeLayout/Component/Application/Mustering';
import EventHistory from '../../HomeLayout/Component/Report/EventHistory';
import SingleEvent from '../../HomeLayout/Component/Report/SingleEvent';
import Account from '../../HomeLayout/Component/Account';
import Facility from '../../HomeLayout/Component/Facility';
import Integrations from '../../HomeLayout/Component/Integrations';

import CreateViewAlert from "../../HomeLayout/Component/Alarm";
import Roles from "../../HomeLayout/Component/Roles/Roles"
import MusterLocation from "../../HomeLayout/Component/Application/MusterLocation"
import PropertyDetailConfiguration from "../../HomeLayout/Component/PropertyDetailConfiguration";
import ManageUser from "../../HomeLayout/Component/ManageUser"
import ProfilePage from "../../HomeLayout/Component/Application/ProfilePage";
import Configuration from '../../HomeLayout/Component/Application/Configuration';
import DeploymentConfiguration from '../../HomeLayout/Component/Application/DeploymentConfiguration';
import Devices from '../../HomeLayout/Component/Devices/Devices';
import ManageDevices from '../../HomeLayout/Component/Devices/ManageDevices';
import Events from '../../HomeLayout/Component/Application/Events';
export const SidebarData = [
	{
		id : 1,
		title: 'Dashboard',
		path: '/home/dashboard',
		navpath: '/dashboard',
		activeIcon: <img src={Active_Dashboard} />,
		component: <Dashboard />,
		icon: <img src={DashboardImg} className="sidebar-menu-item-link-icon" />,
		cName: 'nav-text',
		refName: ["VIEW_DASHBOARD", "VIEW_PROFILE"],
		roles: [1, 2, 3, 4, 5],
		dataTestId:"dashboard-nav",
	},
	{
		id: 2,
		title: 'Users and Groups',
		path: '/home/users',
		navpath: '/users',
		activeIcon: <img src={Active_Users_Groups} />,
		icon: <img src={Users_groups} className="sidebar-menu-item-link-icon" />,
		cName: 'nav-text',
		refName: ["VIEW_USERS", "VIEW_GROUPS", "VIEW_ROLES","VIEW_DEVICES"],
		roles: [3, 4, 5],
		dataTestId:"users-group-nav",
		subNav: [
			{
				title: 'Users',
				path: '/home/users',
				navpath: '/users',
				component: <Users />,
				sidebar: true,
				cName: 'nav-text',
				refName: ["VIEW_USERS"],
				roles: [3, 4, 5],
				dataTestId:"users-nav",
			},
			{
				sidebar: false,
				navpath: '/users/edit-user/:userId',
				component: <ManageUser />,
				roles: [3, 4, 5],
				refName: ["EDIT_USERS"],
			},
			{
				sidebar: false,
				navpath: '/users/add-user',
				component: <ManageUser />,
				roles: [3, 4, 5],
				refName: ["ADD_USERS"],
			},
			{
				title: 'Groups',
				component: <Groups />,
				sidebar: true,
				path: '/home/groups',
				navpath: '/groups',
				cName: 'nav-text',
				refName: ["VIEW_GROUPS"],
				roles: [3, 4, 5],
				dataTestId:"groups-nav",
			},
			{
				sidebar: false,
				navpath: '/groups/:operation',
				component: <Groups />,
				roles: [3, 4, 5],
				refName: ["ADD_GROUPS"],
			},
			{
				title: 'Devices',
				component:<Devices/>,
				sidebar: true,
				path: '/home/devices',
				navpath: '/devices',
				cName: 'nav-text',
				refName: ["VIEW_DEVICES"],
				roles: [3, 4, 5],
				dataTestId:"devices-nav",
			},
			{
				title: 'Roles',
				component: <Roles />,
				sidebar: true,
				path: '/home/roles',
				navpath: '/roles',
				cName: 'nav-text',
				refName: ["VIEW_ROLES"],
				roles: [3, 4, 5],
				dataTestId:"roles-nav",
			},
			{
				sidebar: false,
				navpath: '/roles/:roleId',
				component: <Roles />,
				roles: [3, 4, 5],
				refName: ["EDIT_ROLES"],
			}
		]
	},
	{
		id: 3,
		title: 'System',
		path: '/home/system/server-configuration',
		navpath: '/system/server-configuration',
		activeIcon: <img src={Active_System_icon} />,
		icon: <img src={System_Icon} className="sidebar-menu-item-link-icon" />,
		cName: 'nav-text',
		dataTestId:"systems-nav",
		refName: ["VIEW_SERVER_CONFIG", "VIEW_SERVICE_ALLOC", "VIEW_ACCOUNT", "VIEW_FACILITY", "VIEW_PROPERTIES"],
		roles: [1, 2, 3, 4, 5],
		subNav: [
			{
				title: 'Server Configuration',
				path: '/home/system/server-configuration',
				navpath: '/system/server-configuration',
				component: <ServerConfiguration />,
				sidebar: true,
				cName: 'nav-text',
				refName: ["VIEW_SERVER_CONFIG"],
				roles: [3, 4, 5],
				dataTestId:"server-config-nav",
			},
			{
				title: 'Service Allocation',
				path: '/home/system/service-allocation',
				navpath: '/system/service-allocation',
				component: <ApplicationManagement />,
				sidebar: true,
				cName: 'nav-text',
				roles: [3, 4, 5],
				refName: ["VIEW_SERVICE_ALLOC"],
				dataTestId:"application-management-nav",
			},
			{
				title:'Account',
				path:'/home/system/account',
				navpath:'/system/account',
				component: <Account />,
				sidebar: true,
				cName:'nav-text',
				roles: [1, 2],
				refName: ["VIEW_ACCOUNT"],
				dataTestId:"account-nav",
			},
			{
				title: 'Facility',
				path: '/home/system/facility',
				navpath: '/system/facility',
				component: <Facility />,
				sidebar: true,
				cName: 'nav-text',
				refName: ["VIEW_FACILITY"],
				roles: [1, 2],
				dataTestId:"facility-nav",
			},
			{
				title: 'Integrations',
				path: '/home/system/integrations',
				navpath: '/system/integrations',
				component: <Integrations />,
				sidebar: true,
				cName: 'nav-text',
				refName: ["VIEW_INTEGRATION"],
				roles: [1, 2],
				dataTestId:"integration-nav",
			},
			{
				title: 'Property Details',
				path: '/home/system/configuration/property-details',
				navpath: '/system/configuration/property-details',
				component: <PropertyDetailConfiguration />,
				sidebar: true,
				cName: 'nav-text',
				refName: ["VIEW_PROPERTIES"],
				roles: [3, 4, 5],
				dataTestId:"property-nav",
			},

		]
	},
	{
		id: 4,
		title: 'Application',
		path: '/home/application/mustering',
		navpath: '/application/mustering',
		dataTestId:"application-nav",
		activeIcon: <img src={Active_Application_icon} />,
		icon: <img src={Application} className="sidebar-menu-item-link-icon" />,
		cName: 'nav-text',
		refName: ["VIEW_MUSTERING", "VIEW_MUSTERING_LOCATION", "VIEW_ALARMS","VIEW_ALERT_CONFIGURATION","VIEW_DEPLOYMENT_CONFIGURATION", "VIEW_EVENTS"],
		roles: [3, 4, 5],
		subNav: [
			{
				title: 'Mustering',
				path: '/home/application/mustering',
				navpath: '/application/mustering',
				activeIcon: <img src={Active_Application_icon} />,
				component: <Mustering />,
				sidebar: true,
				cName: 'nav-text',
				refName: ["VIEW_MUSTERING"],
				roles: [3, 4, 5],
				dataTestId:"mustering-nav",
			},{
				title:'Muster Location',
				path: '/home/application/muster-location',
				navpath: '/application/muster-location',
				component: <MusterLocation />,
				sidebar: true,
				cName:'nav-text',
				roles: [3, 4, 5],
				refName: ["VIEW_MUSTERING_LOCATION"],
				dataTestId:"mustering-location-nav",
			},{
				title:'Create & View Alarms',
				path: '/home/application/create-view-alerts',
				navpath: '/application/create-view-alerts',
				component: <CreateViewAlert />,
				sidebar: true,
				cName:'nav-text',
				roles: [3, 4, 5],
				refName: ["VIEW_ALARMS"],
				dataTestId:"create-view-alarm-nav",
			},
			{
				title:'Events',
				path: '/home/application/events',
				navpath: '/application/events',
				component: <Events />,
				sidebar: true,
				cName:'nav-text',
				roles: [3, 4, 5],
				refName: ["VIEW_EVENTS"],
				dataTestId:"event-list",
			},
			{
				id:7,
				title:'Configurations',
				path: '/home/application/configurations/deployment-setup',
				component:  <DeploymentConfiguration />,
				navpath: '/application/configurations/deployment-setup',
				activeIcon: <img src={Active_Application_icon} />,
				refName: ["VIEW_ALERT_CONFIGURATION","VIEW_DEPLOYMENT_CONFIGURATION"],
				sidebar: true,
				cName:'nav-text',
				roles: [3, 4, 5],
				dataTestId:"configuration-nav",
				subNav: [
					{
						title: 'Deployment Setup',
						path: '/home/application/configurations/deployment-setup',
						navpath: '/application/configurations/deployment-setup',
						component: <DeploymentConfiguration />,
						sidebar: true,
						cName: 'nav-text',
						roles: [3, 4, 5],
						refName: ["VIEW_DEPLOYMENT_CONFIGURATION"],
						dataTestId:"alert-configuration-nav",
					},
					{
						title: 'Alert Setup',
						component: <Configuration />,
						path: '/home/application/configurations/alert-setup',
						navpath: '/application/configurations/alert-setup',
						sidebar: true,
						cName: 'nav-text',
						roles: [3, 4, 5],
						refName: ["VIEW_ALERT_CONFIGURATION"],
						dataTestId:"alert-configuration-nav",
					},
				]
			},
			{
				component: <ProfilePage />,
				sidebar: false,
				navpath: '/profile',
				path: '/home/profile',
				roles: [3, 4, 5],
				refName: ["VIEW_PROFILE"],
				dataTestId:"profile-nav",
			},
			
		]
	},
	{
		id: 5,
		title: 'Reports',
		path: null,
		navpath: null,
		activeIcon: <img src={Reports} width="8%" alt="active-icon"/>,
		icon: <img src={Reports} className="sidebar-menu-item-link-icon"alt="side-bar-nav-icon" width="8%" />,
		cName: 'nav-text',
		roles: [3, 4, 5],
		refName: ["VIEW_MUSTERING_HISTORY"],
		dataTestId:"reports-nav",
		subNav: [
			{
				title: 'Mustering Event History',
				path: '/home/reports/eventHistory',
				navpath: '/reports/eventHistory',
				component: <EventHistory />,
				sidebar: true,
				cName: 'nav-text',
				roles: [3, 4, 5],
				refName: ["VIEW_MUSTERING_HISTORY"],
				dataTestId:"mustering-event-history-nav",
			},
			{
				sidebar: false,
				path: '/home/reports/eventHistory',
				navpath: '/reports/eventHistory',
				component: <SingleEvent />,
				cName: 'nav-text',
				roles: [3, 4, 5],
				refName: ["VIEW_MUSTERING_HISTORY"],
			}
		]
	},
	{
		id: 6,
		title: 'Support',
		path: '/home/support',
		navpath: '/support',
		activeIcon: <img src={Support_Icon} width="5%" />,
		icon: <img src={Support_Icon} className="sidebar-menu-item-link-icon" width="7%" />,
		cName: 'nav-text',
		refName: ["DEVICE_LOGS"],
		component: <Support />,
		roles: [3, 4, 5],
		dataTestId:"support-nav",
		subNav: [
			{
				title: 'Device Logs',
				path: '/home/support/deviceLogs',
				navpath: '/support/deviceLogs',
				component: <Support />,
				sidebar: true,
				cName: 'nav-text',
				roles: [3, 4, 5],
				refName: ["DEVICE_LOGS"],
				dataTestId:"device-log-nav",
			}]
	},
]
