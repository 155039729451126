import React from 'react';
import '../style/style.css'
import { Link } from 'react-router-dom';

function BreadCrumb(props) {
	function isLast(index) {
		return index === props.crumbs.length - 1;
	}
	
	return (
		<div className='bread-crums'data-testid={props.dataTest}>
			<nav>
				<ol className='bread'>
					{props.crumbs && props.crumbs.map((crumb, ci) => {
						const disabled = isLast(ci) ? 'disabled' : '';
						return (
							<li key={ci}>
									<button className='bread-btn'>
										<div  data-testid={props.dataTestid} onClick={
											crumb.onClickfunction && crumb.onClickfunction
										}>
											{crumb.display}
										</div>
									</button>
							</li>
						)
					})}
				</ol>
			</nav>
		</div>
	);
}
export default BreadCrumb;
