import React from 'react'
import { useEffect } from 'react';
import "./Configuration.css"
import BreadCrumb from "../../../common/component/BreadCrumb";
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import ButtonField from '../../../common/component/ButtonField';
import { setLoading, setSnackData } from '../../../redux/action/userAction';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePermission } from "../../../common/functions/permissionFunctions";
function Configuration() {
	const dispatch = useDispatch()
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const [alertDetails, setAlertDetails] = useState([])
	const [allAlertSetupUpDetails, setAllAlertSetupDetails] = useState()
	useEffect(() => {
		fetchAlertSetupDetails()
	}, [])
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt="arrow-icon"/>,type:'img'},
		{link:"/home/application/mustering",display:"Application",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt="arrow-icon"/>,type:'img'},
		{link:"/home/application/configurations/deployment-setup",display:"Configurations",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon} alt="arrow-icon"/>,type:'img'},
		{link:"/home/application/configurations/alert-setup",display:"Alert Setup",type:"react"},
	];
	const fetchAlertSetupDetails = async () => {
		dispatch(setLoading(true))
		let url = URL.alertSetUp + "?facilityId=" + localStorage.getItem("facilityId") + "&type=2";
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				setAlertDetails(response.result.result.Alert)
				setAllAlertSetupDetails(response.result.result)
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const UpdateAlertSetUpDetails = async()=>{
		dispatch(setLoading(true))
		let url = URL.updateAlertSetUp + "?facilityId=" + localStorage.getItem("facilityId");
		let data = {
			id: allAlertSetupUpDetails.id,
			facilityId: allAlertSetupUpDetails.facilityId,
			...alertDetails
		}
		let response = await API.putAPI(url, data)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"success"
				}
				dispatch(setSnackData(snackData))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down.Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	
	return (
		<React.Fragment>
			<div className='alert-setup-container'>
				<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
				<div className='list-page-title-div'>
					<div className='list-page-title' data-testid="alert-set-up-title">Alert Setup</div>
				</div>
				<div className='alert-configuration-ctr'>
					<div className='alert-title' data-testid="alert-title">Alert Configurations</div>
					<div className='alert-display-order-ctr'>
						<div className='alert-title alert-sub-title'>Alert Display Order</div>
						<div className='alert-display-sort-ctr'>
							<div className='alert-display-radio-ctr'>
								<label
									data-testid={"role-radio-input-label"}
									id="role-radio-input"
									onClick={()=>{
										setAlertDetails({...alertDetails , alertDisplayOrder : 1})
									}}
								>
									Ascending
								</label>
								<input
									type="radio"
									name="Role"
									data-testid={'alert-asc-radio-input'}
									onChange={() => {}}
									id="role-input"
									checked={ alertDetails  && alertDetails.alertDisplayOrder === 1}
									onClick={()=>{
										setAlertDetails({...alertDetails , alertDisplayOrder : 1})
									}}
								/>
							</div>
							<div>
								<div  className='alert-display-radio-ctr'>
										<label
											onClick={()=>{
												setAlertDetails({...alertDetails , alertDisplayOrder : 2})
											}}
											data-testid={"role-radio-input-label"}
											id="role-radio-input"
										>
											Descending
										</label>
										<input
											checked={alertDetails  && alertDetails.alertDisplayOrder === 2}
											type="radio"
											name="Role"
											data-testid={'alert-desc-radio-input'}
											onClick={()=>{
												setAlertDetails({...alertDetails , alertDisplayOrder : 2})
											}}
											id="role-input"
										/>								
								</div>
							<div className='alert-order-hint'>Most recent on top</div>
							</div>
							
							
						</div>
						
						
					</div>
					{validatePermission(userPermissions, "EDIT_ALERT_CONFIGURATION") ? 
							<div className='create-alert-btn-container alert-setup-btn-ctr'>
							<ButtonField label="Save" type="submit" dataTestid="save-alert-configuration-btn" id={"SaveBtn"} onClick={()=>{UpdateAlertSetUpDetails()}}/>
						</div>
						:null}
					 
				
				</div>
			</div>
		</React.Fragment>
		
	)
}


export default Configuration
