import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL";
import { setSnackData } from "../../redux/action/userAction";
import * as validateFunctions from "../../common/functions/validateFunctions";
import * as commonFunctions from "../../common/functions/commonFunctions";

function AddIntegration(props) {
	var dispatch = useDispatch()
	const accounts = useSelector(state => state.accountReducer.accounts);
	const facilities = useSelector(state => state.accountReducer.facilities);

	const [propsData, setPropsData] = useState(props.data);
	const [errorMessage, setErrorMessage] = useState(false);
	
	const [account, setAccount] = useState("");
	const [accountError, setAccountError] = useState(false);

	const [facility, setFacility] = useState("");
	const [facilityError, setFacilityError] = useState(false);

	const [integrationName, setIntegrationName] = useState("");
	const [integrationNameError, setIntegrationNameError] = useState(false);

	const [integrationUserName, setIntegrationUserName] = useState("");
	const [integrationUserNameError, setIntegrationUserNameError] = useState(false);

	const [integrationUserPassword, setIntegrationUserPassword] = useState("");
	const [integrationUserPasswordError, setIntegrationUserPasswordError] = useState(false);

	useEffect(() => {
		if (propsData.id) {
			if (propsData.facilityId) {
				let tempFacilities = facilities.filter(facility => facility.id == propsData.facilityId)
				if (tempFacilities[0]) {
					setAccount(tempFacilities[0].accountId)
				}
			}
			
			setFacility(propsData.facilityId || "")
			setIntegrationName(propsData.firstName || "")
			setIntegrationUserName(propsData.userName || "")
			setIntegrationUserPassword(propsData.lastName || "")
		}
	}, []); 

	const generateUserId = ()=>{
		let userId = commonFunctions.makeid(10) + "-" + commonFunctions.makeid(10) + "-" + commonFunctions.makeid(10)
		setIntegrationUserName(userId)
		setIntegrationUserNameError(false)
	}
	const generateSecretKey = () => {
		let secretKey = commonFunctions.makeid(10) + "-" + commonFunctions.makeid(10) + "-" + commonFunctions.makeid(10)
		setIntegrationUserPassword(secretKey)
		setIntegrationUserPasswordError(false)
	}
	const saveIntegration = async () =>{
		let accountValidation = validateFunctions.validateInput(account, setAccountError, "numeric"),
			facilityValidation = validateFunctions.validateInput(facility, setFacilityError, "numeric"),
			nameValidation = validateFunctions.validateInput(integrationName, setIntegrationNameError, "alphanumeric"),
			userNameValidation = validateFunctions.validateInput(integrationUserName, setIntegrationUserNameError, "any"),
			userPasswordValidation = validateFunctions.validateInput(integrationUserPassword, setIntegrationUserPasswordError, "any");

		if (accountValidation &&
			facilityValidation &&
			nameValidation && 
			userNameValidation && 
			userPasswordValidation
		) {
			let requestData = {
				"facilityId" : facility,
				"firstName" : integrationName,
				"userName" : integrationUserName,
				"password" : integrationUserPassword,
				"lastName": integrationUserPassword,
				functionalRoleId : 6
			}

			let response = null;
			if (propsData.id) {
				requestData["id"] = propsData.id;
				response = await API.putAPI(URL.integrations, requestData);
			} else {
				response = await API.postAPI(URL.integrations, requestData);
			}
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
					props.onCancel()
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else if (response.fetchStatus === "failure") {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}

	return (
		<div className="modal">
			<div className="modal-content-container add-integrations-modal">
				<div className="modal-content add-integration-model-content ">
					<div className="modal-title" data-testid="integration-title">
						{propsData.id ?
							"Update Integration"
							:
							"Add Integration"
						}
					</div>
					<div className='modal-inner-container'>
						<div className='response-container'>{errorMessage}</div>
						<div className='integrations-form-container'>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label>Account *</label>
								</div>
								<div className='integrations-input-container'>
									<select
										autoFocus
										value={account}
										className={("integrations-input ") + (accountError ? " invalid " : "")}
										onChange={(event) => {validateFunctions.validateInputChange(event, setAccount, setAccountError);setFacility("")}}
										disabled={propsData.id? true : false}
										data-testid="account-select-box"
										id="account-select-box"
									>
										<option value="">Select Account</option>
										{accounts.map((account, index) =>
											<option key={index} name={account.accountName} data-testid={"account-list-"+index} id={"account-list-"+index} value={account.id}>{account.accountName}</option>
										)}
									</select>
									<div className='input-error-message' data-testid="account-error" id="account-error" >{accountError}</div>
								</div>
							</div>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label>Facility *</label>
								</div>
								<div className='integrations-input-container'>
									<select
										value={facility}
										className={("integrations-input ") + (facilityError ? " invalid " : "")}
										onChange={(event) => validateFunctions.validateInputChange(event, setFacility, setFacilityError)}
										data-testid="facility-select-box"
										id="facility-select-box"
										disabled={propsData.id ? true : false}
									>
										<option value="">Select Facility</option>
										{facilities.map((facility, index) =>{
											if (facility.accountId == account) {
												return <option key={index} name={facility.siteName} data-testid={"facility-list-"+index} id={"facility-list-"+index} value={facility.id}>{facility.siteName}</option>
											}
										})}
									</select>
									<div className='input-error-message' data-testid="facility-error" id="facility-error"  >{facilityError}</div>
								</div>
							</div>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label>Integration Name *</label>
								</div>
								<div className='integrations-input-container'>
									<input
										value={integrationName}
										data-testid="integration-name-input"
										id="integration-name-input"
										className={("integrations-input ") + (integrationNameError ? " invalid " : "")}
										onChange={(event) => validateFunctions.validateInputChange(event, setIntegrationName, setIntegrationNameError)}
									/>
									<div className='input-error-message' data-testid="integration-name-error" id="integration-name-error" >{integrationNameError}</div>
								</div>
							</div>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label>Integration User Id *</label>
								</div>
								<div className='integrations-input-container'>
									<input
										readOnly
										value={integrationUserName}
										data-testid="integration-user-id-input"
										id="integration-user-id-input"
										className={("integrations-input ") + (integrationUserNameError ? " invalid " : "")}
										onChange={(event) => validateFunctions.validateInputChange(event, setIntegrationUserName, setIntegrationUserNameError)}
									/>
									<div className='input-error-message' id="integration-user-id-error" data-testid="integration-user-id-error" >{integrationUserNameError}</div>
								</div>
							</div>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label></label>
								</div>
								<div className='integrations-input-container'>
									<button className='small-button' data-testid="generate-user-id-btn" id="generate-user-id-btn" onClick={()=> generateUserId()}>Generate User ID</button>
								</div>
							</div>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label>Integration Secret Key *</label>
								</div>
								<div className='integrations-input-container'>
									<input
										readOnly
										value={integrationUserPassword}
										className={("integrations-input ") + (integrationUserPasswordError ? " invalid " : "")}
										data-testid="integration-secret-key-input"
										id="integration-secret-key-input"
										onChange={(event) => validateFunctions.validateInputChange(event, setIntegrationUserPassword, setIntegrationUserPasswordError)}
									/>
									<div className='input-error-message' data-testid="integration-secret-error" id="integration-secret-error" >{integrationUserPasswordError}</div>
								</div>
							</div>
							<div className='integrations-label-input-container'>
								<div className='integrations-label-container'>
									<label></label>
								</div>
								<div className='integrations-input-container'>
									<button className='small-button' data-testid="generate-secret-key-btn" id="generate-secret-key-btn" onClick={() => generateSecretKey()}>Generate Secret Key</button>
								</div>
							</div>
						</div>
						<div className="popup-button-container">
							<button className="cancel-button" data-testid="integration-cancel-btn" id="integration-cancel-btn" onClick={(e) => props.onCancel()}>Cancel</button>
							<div class="vertical_line" ></div>
							<button className="save-button" data-testid="integration-add-btn" id="integration-add-btn" onClick={() => saveIntegration()} >
								{propsData.id ?
									"Update Integration"
									:
									"Add Integration"
								}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default AddIntegration;
