import { useState, useEffect, useRef } from "react";
import '../style/DropDownSearch.css';
import Downarrow from '../../assets/svg/dropdown_arrow.svg';

export default function SearchabelDropDown(props){
    const {optionList, placeholder, onChangeMethod, selectedOption} = props
    const [inputDisplay, setInputDisplay] = useState("")
    const [option, setOption] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false)
    const [searchWord, setSearchWord] = useState("")
    const [activeEle, setActiveEle] = useState("")
    const [filteredOption, setFilteredOption] = useState("")
    const clickIdentifyRef = useRef(null)
    const resetScroll = useRef()
    const dropDownContentRef = useRef()

    useEffect(()=>{
        const handleClickOutside = (event) => {
            if (clickIdentifyRef.current && !clickIdentifyRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    
    },[]);

    useEffect(()=>{
        if(selectedOption){
            let index = optionList.map((o) => o.id).indexOf(selectedOption);
            setInputDisplay(optionList[index].value)
        }
        else
            setInputDisplay('')
    },[selectedOption]);

    useEffect(()=>{
        if(optionList){
            setOption(optionList)
            setFilteredOption(optionList)
        }
    },[optionList]);


    useEffect(()=>{
        setActiveEle("")
        setSearchWord("")
    },[showDropdown]);

    useEffect(()=>{
        // if (searchWord){
        if (option){
            let tempFilteredOption = option.map((o)=>{
                if(o.value.toLowerCase().includes(searchWord.toLowerCase()))
                    return o;
            });
        tempFilteredOption = tempFilteredOption.filter( Boolean );
        setFilteredOption(tempFilteredOption);
        setActiveEle(0);
        }
        // else{
        //     setFilteredOption(option);
        //     setActiveEle(0);
        // }
    },[searchWord, option]);

    const handleDownKeyOnSearch = (e) => {
        if (e.key === "ArrowDown"){
            let activeEleTemp = activeEle
            if (activeEleTemp < filteredOption.length-1){
                if(activeEleTemp === ""){
                    activeEleTemp = 0
                }else{
                    activeEleTemp = (activeEleTemp >= 0 ? activeEleTemp+1 : 0 ) 
                }
                setActiveEle( activeEleTemp)
                if(activeEleTemp && document.getElementsByClassName("list-item")[activeEleTemp])
                    document.getElementsByClassName("list-item")[activeEleTemp].scrollIntoView({block: "end", inline: "nearest"})
            }
        }else if (e.key === "ArrowUp"){
            let activeEleTemp = activeEle
            if(activeEleTemp>0){
                activeEleTemp = (activeEleTemp-1) % (filteredOption.length)
                setActiveEle( activeEleTemp)
                if(activeEleTemp && document.getElementsByClassName("list-item")[activeEleTemp])
                    document.getElementsByClassName("list-item")[activeEleTemp].scrollIntoView({block: "end", inline: "nearest"})
            }
        }else if (e.key === "Enter"){
            e.preventDefault();
            onChangeMethod(filteredOption[activeEle].id);
            setShowDropdown(false);
        }else if (e.key === "Escape"){
            setShowDropdown(false);
        }
    };

    const mouseHoverSelection = (e) => {
        let eleIndex = filteredOption.map((o) => o.id).indexOf(parseInt(e.target.attributes.value.nodeValue))
        setActiveEle(eleIndex)
    };


    return(
        <div className="drpdwn-outer" ref={clickIdentifyRef}>
        <div tabIndex={0} className="drpdwn-input" onClick={() => {setShowDropdown(!showDropdown);/*resetScroll.current.scrollTop=0*/}}>
            <div className="input-content" data-testid="service-allocation-user-drop-down" id="service-allocation-user-drop-down">{inputDisplay ? inputDisplay : placeholder? placeholder : "Select option"}</div>
            <img src={Downarrow} alt="down arrow"/>
        </div>
        {
            showDropdown ?(
                <div className="drpdwn-inner" ref={resetScroll} >
                    <div className="dropdown-search">
                        <input className="drpdwn-search" data-testid="input-box-search"
                        id="input-box-search"
                        onKeyDown={(e)=> handleDownKeyOnSearch(e)}
                        value={searchWord} onChange={(e) => {setSearchWord(e.target.value)}} autoFocus/>
                    </div>
                    <div className="drpdown-content" ref={dropDownContentRef}>
                        {/* {optionList && optionList.map((o, index)=>{
                            if(o.value.toLowerCase().includes(searchWord.toLowerCase()))
                                return (
                                <div className={"list-item" + (activeEle === index ? " active-option" :"" )}
                                key={index} 
                                value={o.id}
                                // tabIndex={0}
                                onClick={()=> {onChangeMethod(o.id);setShowDropdown(false);setSearchWord('')}}>
                                {o.value}</div>)
                            }) */}
                            {
                                filteredOption && filteredOption.map((o, index)=>{
                                    if(o)
                                        return (
                                        <div data-testid="select-option-saerch-data" id="select-option-saerch-data" className={"list-item" + (activeEle === index ? " active-option" :"" )}
                                        key={index} 
                                        value={o.id}
                                        onMouseOver={(e)=>mouseHoverSelection(e)}
                                        // onMouseLeave={}
                                        // tabIndex={0}
                                        onClick={()=> {onChangeMethod(o.id);setShowDropdown(false);setSearchWord('')}}>
                                        {o.value}</div>)
                                    })
                            }
                    </div>
                </div>
            ) : null
        }
        </div>

    )
}