import './AddNewIcon.css';
import { useState, useRef } from 'react';
import * as functions from "../../../common/functions/validateFunctions";
import { useDispatch } from 'react-redux';
import CONSTURL from "../../../common/api/constantURL";
import * as API from "../../../common/api/index"
import { setSnackData, setAlertIcon } from "../../../redux/action/userAction";
function AddNewIcon(props) {
	var dispatch = useDispatch()
	const [userInfo, setuserInfo] = useState({
		file: [],
		filepreview: null,
	});
	const fileUpload = useRef(null);
	const [IconName, setIconName] = useState("")
	const [errorMessage, setErrorMessage] = useState("")
	const [validAddIconForm, setValidAddIconForm] = useState(true)
	const handleInputChange = (event) => {
		let validFileExtensions = ["image/svg", "image/png", "image/svg+xml"];
		setValidAddIconForm(true)
		setuserInfo({
			file: "",
			filepreview: null,
		});
		if (validFileExtensions.includes(fileUpload.current.files[0].type)) {
			setuserInfo({
				file: event.target.files[0],
				filepreview: URL.createObjectURL(event.target.files[0]),
			});
		} else {
			fileUpload.current.value = ''
			setErrorMessage("Upload file with PNG, SVG format")
			return setValidAddIconForm(false)
		}
	}
	const addIconsubmitHandler = async (e) => {
		e.preventDefault()
		if (!functions.validateInput(IconName.trim(), setErrorMessage)) {
			setErrorMessage("Icon name is required")
			return setValidAddIconForm(false)
		}
		if (userInfo.file.length <= 0) {
			setErrorMessage("Upload Icon")
			return setValidAddIconForm(false)
		}

		let validFileExtensions = ["image/svg", "image/png", "image/svg+xml"];
		if (validFileExtensions.includes(fileUpload.current.files[0].type)) {
			const fileSize = userInfo.file.size / 1024 / 1024;
			if (fileSize > 0.5) {
				setErrorMessage("Please upload a file smaller than 500 KB.")
				return setValidAddIconForm(false)
			}
		} else {
			setErrorMessage("Upload file with PNG, SVG format")
			return setValidAddIconForm(false)
		}
		var form_data = new FormData();
		form_data.append("name", IconName.trim())
		form_data.append("iconFile", userInfo.file)
		form_data.append("facilityId", localStorage.getItem("facilityId"))
		if (userInfo.file.length > 0) {
			form_data.append("profilePicture", (userInfo.file.length > 0 ? userInfo.file : ""));
		}
		let response = await API.postWithFileAPI(CONSTURL.icon, form_data)
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "success"
				}
				dispatch(setSnackData(snackData))
				props.setTrigger(false)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server Down.Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	return (
		<div className="add-new-icon-modal" data-testid="add-new-icon-modal">
			<div className="add-new-icon-modal-content-container add-new-icon-model">
				<form className="add-new-icon-model-container" onSubmit={addIconsubmitHandler}>
					<div className="add-new-icon-model-title" data-testid="add-new-icon-model-title" id="add-new-icon-model-title">
						Add New Icon
					</div>

					<div className="add-new-icon-model-icon-label">
						<label data-testid="icon-name-label">Icon Name: </label>
						<input type="text" className="add-new-icon-model-icon-input-box" autoFocus={true} onChange={(e) => {
							setValidAddIconForm(true)
							setIconName(e.target.value)
						}} data-testid="icon-name-inputBox" id="icon-name-inputBox"
						/>
					</div>
					<div className="file-adding-container">
						<div className="file-box">
							<input type="text" defaultValue={userInfo.file.name ? userInfo.file.name : null} className="add-new-icon-model-icon-input-box" data-testid="selected-icon-name-inputBox"/>
						</div>
						<div className="small-button">
							<div className="inputfile-box">
								<input type="file" id="add-alarm-icon" data-testid="chooseFileBtn" className="inputfile" ref={fileUpload} onChange={(e) => { handleInputChange(e) }} />
								<label htmlFor="add-alarm-icon">
									<span id="file-name" className="file-box"></span>
									<span className="small-button" data-testid="chooseFilelabel" id="chooseFilelabel">
										Choose File
									</span>
								</label>
							</div>
						</div>
						{userInfo.file.name ? null :
							<div className="no-file-option" data-testid="no-file-option">
								No file choosen
							</div>
						}
					</div>
					<div className="selected-img" data-testid="selected-img">
						{userInfo.filepreview !== null ?
							<img className="previewimg" src={userInfo.filepreview} alt="UploadImage" data-testid="preview-img"/>
							:
							null}
					</div>
					<div className="image-restriction">
						<div className="img-type" data-testid="img-type">
							(SVG or PNG)
						</div>
						<div className="img-type-restriction" data-testid="img-type-restriction">
							Icons should be 50px wide by 50px tall.
							One color SVG file types are preferable. Contact support for assistance.
						</div>
						<div className="responsive-seleted-image" data-testid="responsive-seleted-image">
							{userInfo.filepreview !== null ?
								<img className="previewimg" src={userInfo.filepreview} alt="UploadImage" data-testid="res-preview-img"/>
								:
								null}
						</div>
					</div>
					<div className="user-error-msg add-icon-error-msg" data-testid="add-icon-error-msg">
						{!validAddIconForm && errorMessage}
					</div>
					<div className="popup-button-container add-icon-btn-container">
						<button className="cancel-button" type="button" onClick={() => props.setTrigger(false)} data-testid="cancel-btn" id="cancel-btn">Cancel</button>
						<div class="vertical_line" ></div>
						<button className="save-button" type="submit" onClick={addIconsubmitHandler} data-testid="add-icon-btn" id="add-icon-btn">Add Icon</button>
					</div>
				</form>
			</div>
		</div>
	)
}
export default AddNewIcon;
