import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import * as functions from "../../common/functions/validateFunctions";
import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL"
import { setSpecificFacilityDetail } from '../../redux/action/accountAction';
import { setSnackData, setLoading } from '../../redux/action/userAction';
import { validatePermission } from "../../common/functions/permissionFunctions";
import "../Style/PropertyDetailConfiguration.css";

function PropertyDetailConfiguration() {
	const dispatch = useDispatch();
	const propertyDetail = useSelector(state => state.accountReducer.facilityDetail)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} />, type: 'img' },
		{ link: "/home/system/server-configuration", display: "System", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} />, type: 'img' },
		{ link: "/home/system/configuration/property-details", display: "Property Details", type: "react" },
	];

	const [propertName, setPropertyName] = useState("");
	const [propertNameError, setPropertyNameError] = useState("");

	const [streeName, setStreetName] = useState("");
	const [streeNameError, setStreetNameError] = useState("");

	const [streeName1, setStreetName1] = useState("");
	const [apartmentNo, setApartmentNo] = useState("")

	const [cityName, setCityName] = useState("");
	const [cityNameError, setCityNameError] = useState("");

	const [stateName, setStateName] = useState("");
	const [stateNameError, setStateNameError] = useState("")

	const [pinCode, setPinCode] = useState("");
	const [pinCodeError, setpinCodeError] = useState("")

	const [countryName, setCountryName] = useState("");
	const [countryNameError, setCountryNameError] = useState("")

	const [NoChangeError, setNoChangeError] = useState("")

	const fetchFacilityDetails = async () => {
		let url = URL.getFacilityById + "?id=" + localStorage.getItem("facilityId");
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				dispatch(setSpecificFacilityDetail(response.result.result))
				setPropertyName(response.result.result.siteName || "")
				setStreetName(response.result.result.address || "")
				setStreetName1(response.result.result.address1 || "")
				setApartmentNo(response.result.result.apt || "")
				setCityName(response.result.result.city || "")
				setPinCode(response.result.result.zipCode || "")
				setCountryName(response.result.result.countryCode || "")
				setStateName(response.result.result.state || "")

			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
		dispatch(setLoading(false))
	}

	useEffect(() => {
		dispatch(setLoading(true))
		fetchFacilityDetails()
	}, [])

	const propertyDetailFormHandler = async (e) => {
		e.preventDefault();
		let validPropertyName = functions.validateInput(propertName, setPropertyNameError, "all");
		let validStreetName = functions.validateInput(streeName, setStreetNameError, "all");
		let validCityName = functions.validateInput(cityName, setCityNameError, "alphabetic", false);
		let validCountryName = functions.validateInput(countryName, setCountryNameError, "alphabetic", false);
		let validStateName = functions.validateInput(stateName, setStateNameError, "alphabetic", false);
		let validPinCodeName = functions.validateInput(pinCode, setpinCodeError, "alphanumeric", false);
		if (propertName == propertyDetail.siteName && streeName == propertyDetail.address && stateName == propertyDetail.state && cityName == propertyDetail.city && pinCode == propertyDetail.zipCode && countryName == propertyDetail.countryCode && streeName1 == propertyDetail.address1 && apartmentNo == propertyDetail.apt) {
			setNoChangeError("No Change Made")
		} else {
			let data = {
				"id": propertyDetail.id,
				"accountId": propertyDetail.accountId,
				"siteName": propertName,
				"address": streeName,
				"city": cityName,
				"zipCode": pinCode,
				"address1": streeName1,
				"apt": apartmentNo,
				"state": stateName,
				"countryCode": countryName,
				"contact": propertyDetail.contact,
				"userLicenseLimit": propertyDetail.userLicenseLimit,
			}

			var form_data = new FormData();
			for (var key in data) {
				form_data.append(key, data[key])
			}
			if (validPropertyName && validStreetName && validPinCodeName && validCityName && validCountryName && validStateName) {
				let response = await API.putwithFileAPI(URL.facility, form_data);
				if (response.fetchStatus === "success") {
					if (response.result.status === "success") {
						let snackData = {
							showSnack: true,
							snackMessage: response.result.message,
							snackVariant: "success"
						}
						dispatch(setSnackData(snackData))
						fetchFacilityDetails()
					} else {
						let snackData = {
							showSnack: true,
							snackMessage: response.result.message,
							snackVariant: "error"
						}
						dispatch(setSnackData(snackData))
					}
				} else if (response.fetchStatus === "failure") {
					let snackData = {
						showSnack: true,
						snackMessage: "Unable to update. Try again later!",
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			}
		}

	}
	return (
		<div className='property-details-container' data-testid="property-details-container">
			<BreadCrumb crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<form className='property-detail-section' onSubmit={propertyDetailFormHandler} data-testid="form-submit">
				<div className='list-page-title-div'>
					<div className='list-page-title' data-testid="property-details-title">Property Details</div>
				</div>
				<div className='property-detail-card-section'>
					<div className='property-ctr'>
						<label data-testid="property-name-label">Property Name*</label>
						<input 
							data-testid="property-name-input"
							maxLength={50} 
							onChange={(e) => { setPropertyName(e.target.value); setNoChangeError(""); setPropertyNameError("") }} value={propertName} className={propertNameError ? "invalid" : ""} autoFocus></input>
						<div className='input-error-message' data-testid="property-name-error">{propertNameError}</div>
					</div>
					<div className='address-ctr'>
						<div className='Address-title' data-testid="address-label">Address</div>
						<div className='street-suit-ctr'>
							<div className='street-input'>
								<label data-testid="street-label">Street*</label>
								<input 
								data-testid="street-input"
								id="street-input"
								maxLength={50} onChange={(e) => { setStreetName(e.target.value); setNoChangeError(""); setStreetNameError("") }} value={streeName} className={streeNameError ? "invalid" : ""}></input>
								<div className='input-error-message' id="street-input-error" data-testid="street-input-error">{streeNameError}</div>
							</div>
							<div>
								<label data-testid="apt-label">Apt/Suite #</label>
								<input 
								data-testid="apt-input"
								id="apt-input"
								maxLength={10} value={apartmentNo} onChange={(e) => { setApartmentNo(e.target.value); setNoChangeError(""); }}></input>
							</div>

						</div>
						<div className='street-2-ctr'>
							<label data-testid="street2-label">Street 2</label>
							<input 
							data-testid="street2-input"
							id="street2-input"
							maxLength={50} value={streeName1} onChange={(e) => { setStreetName1(e.target.value); setNoChangeError(""); }}></input>
						</div>
						<div className='city-state-zip-ctr'>
							<div className='city-input-ctr'>
								<label data-testid="city-label">City</label>
								<input 
								data-testid="city-input"
								id="city-input"
								maxLength={50} onChange={(e) => { setCityName(e.target.value); setCityNameError(""); setNoChangeError(""); }} value={cityName} className={cityNameError ? "invalid" : ""}></input>
								<div className='input-error-message' id="city-input-error" data-testid="city-input-error" >{cityNameError && "Alphabetic Characters Only"}</div>
							</div>
							<div className='state-province-select-ctr'>
								<label data-testid="state-label">State/Province</label>
								<input 
								data-testid="state-input"
								id="state-input"
								maxLength={50} onChange={(e) => { setStateName(e.target.value); setStateNameError(""); setNoChangeError(""); }} value={stateName} className={stateNameError ? "invalid" : ""} />
								<div className='input-error-message' id="state-input-error" data-testid="state-input-error">{stateNameError && "Alphabetic Characters Only"}</div>

							</div>
						</div>
						<div className='zip-country-ctr'>
							<div className='zipcode-input-ctr'>
								<label data-testid="zip-label">Zip Code</label>
								<input 
								data-testid="zip-input"
								id="zip-input"
								maxLength={25} onChange={(e) => { setPinCode(e.target.value); setpinCodeError(""); setNoChangeError(""); }} className={pinCodeError ? "invalid" : ""} value={pinCode}></input>
								<div className='input-error-message' id="zip-input-error" data-testid="zip-input-error">{pinCodeError && "AlphaNumeric Values Only."}</div>
							</div>
							<div className='country-select-ctr'>
								<label data-testid="country-label">Country</label>
								<input 
								data-testid="country-input"
								id="country-input"
								maxLength={50} onChange={(e) => { setCountryName(e.target.value); setCountryNameError(""); setNoChangeError(""); }} className={countryNameError ? "invalid" : ""} value={countryName} />
								<div className='input-error-message' id="country-input-error" data-testid="country-input-error">{countryNameError && "Alphabetic Characters Only"}</div>
							</div>
						</div>
						<div className='input-error-message no-change-error' id="No-change-error" data-testid="No-change-error">{NoChangeError}</div>
					</div>
					{validatePermission(userPermissions, "UPDATE_PROPERTIES") ?
						<div className='property-btn-ctr'>
							<button className='save-button' type='submit' id="saveBtn" data-testid="saveBtn">Save</button>
						</div>
					: null}
				</div>
			</form>
		</div>
	)
}

export default PropertyDetailConfiguration
