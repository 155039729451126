import React,{useEffect,useState} from 'react'
import { useSelector,useDispatch } from 'react-redux';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import ButtonField from '../../../common/component/ButtonField';
import Table from '../../../common/component/Table';
import "./ViewGroup.css"
import AddGroups from './AddGroups';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setGroups } from '../../../redux/action/groupAction';
import { setSnackData } from '../../../redux/action/userAction';
import { validatePermission } from "../../../common/functions/permissionFunctions";
import {IoArrowBackOutline} from "react-icons/io5"
import { setLoading } from '../../../redux/action/userAction';
import {getRoleName} from "../../../common/functions/commonFunctions"
function ViewGroup(props) {
	var dispatch =  useDispatch()
	const groups = useSelector(state => state.groupReducer.groups)
	const users = useSelector(state => state.userReducer.users)
	const userTypes = useSelector(state => state.userReducer.userTypes)
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
	var [search,setSearch] = useState("")
	var [openModal,setOpenModal] = useState(false)
	var [groupName,setGroupName] = useState(props.role)
	const [modalProps, setModalProps] = useState({});
	const [groupData,setGroupData] =  useState({ 
		id:"",
		groupName:"",
		userIds:[]
	})
	const [userData,setUserData] =  useState([])
	const viewGroupClose=()=>{
		props.onClose()
	}
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon}/>,type:'img'},
		{link:"/home/users",display:"Users and Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon}/>,type:'img'},
		{link:"/home/groups",display:"Groups",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon}/>,type:'img'},
		{link:"/home/groups",display:groupData.groupName,type:"react"}
	]; 
	let tableHeaders = [
		{ id: 'firstName', label: 'First Name', width: 100, responsive: true,dataTestid: "view-first-name-col", sortable: true },
		{ id: 'lastName', label: "Last Name", width: 100, dataTestid: "view-last-name-col",responsive: true,sortable: true },
		{ id: "role", label: "Role", width: 100, dataTestid: "view-role-name-col",responsive: true, },
		{ id: "userName", label: "Email", width: 100, dataTestid: "view-user-name-col",responsive: true },
	]
	useEffect(() => {
		dispatch(setLoading(true))
		let tempGroupData={
			id:"",
			groupName:"",
		}
		let userDetails =[]
		groups.map((group)=>{
			if(group.id == groupName){
				setModalProps(group)
				tempGroupData = {
					id:group.id||"",
					groupName:group.groupName||"",                                    
				} 
				users.map((user)=>{
					group.userIds.map((id,index)=>{
						if(id == user.id){
							userDetails.push(user)
							userDetails.map((userDetail)=>{
								userDetail.role =  userDetail.roleIds ? getRoleName(userTypes,  userDetail.roleIds ) : '-'   
							})                       
						}
					})
				})
			}
		})
		setGroupData(tempGroupData)
		setUserData(userDetails)
		dispatch(setLoading(false))
	}, [groupName,groups])
	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = groupList[i].userIds.length
				}
				dispatch(setGroups(groupList))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Unable to fetch Groups. Try Again!",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const filter = (values) =>{
		let tempValues = []
		if(search && search.length > 0){
			values.map((value)=>{  
				if(
					(value.firstName !== null && value.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase())) ||
					(value.lastName !== null && value.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase())) ||
					(value.userName !== null && value.userName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
				){
					tempValues.push(value)
				}
			})
		}
		if (!search){
			tempValues = values;
		}
		return tempValues      
	}
	return (
		<div className='specific-group-management-container' data-testid="vie">
			<BreadCrumb  crumbs={BreadCrumbArr} dataTestid={"view-group-breadcrum"} ></BreadCrumb>
			<div className='specific-group-management-title'>Group Management Options</div>
			
				<div className='specific-group-management-btn-field'>
				{validatePermission(userPermissions, "EDIT_GROUPS") ?<ButtonField label="Edit Group" dataTestid="edit-group-btn" onClick={(e) => setOpenModal(true)} />: ""}
				<button onClick={(e) => props.onClose()} data-testid="back-to-group-btn"><IoArrowBackOutline /> Back to group</button>
				</div>
			
			<div className='specific-grouup-management-filter-container'>
				<div  className='specific-grouup-management-selection-filter'>
					<label className='specific-group-management-title'>Group Name</label>
					<select 
						onChange={
							(e)=>{
								if(e.target.value == "All"){
									props.onClose()
								}
								setGroupName(e.target.value)
							}} 
						data-testid="view-group-select-drop-down"
						value={groupName}
					>
						<option value="All">All</option>
						{groups.map((group, index) =>   
							!group.isEventGroup ? 
							<option key={index} value={group.id}>{group.groupName}</option> : ""
						)}    
					</select>
				</div>
				<div className='search'>
					<input type="text" className="usersSearch" value = {search} placeholder="Search" data-testid="view-group-search-input" onChange={(e)=>{setSearch(e.target.value)}} />
				</div>
				<div style={{ display: "flex" }} className="reclear view-grp-reset-btn-ctr">
					<div data-testid="users-reset-btn" id="users-reset-btn"  className='pointer' onClick={()=>{setSearch('')}} >Reset</div>
				</div>
				
			</div>
			<div className='specific-group-management-table-container'>
					<Table
						headers={tableHeaders}
						data={filter(userData)}
						defaultOrderBy={"firstName"}
						defaultSortOrder={"asc"}
						editAction
						deleteAction
						tableTitle={"Number of Users"}
					/>
			</div>
			{false ?   
				<div>   
					<div className='specific-group-management-service-allotement-container'>
						<div className='specific-group-service-ctr'><div className='service-allotement-title'>Services for Students Grade 10</div><div className='add-additional-ctr'>Add Additional Services</div></div>
						<div className='service-allotement-checkbox-container'><span>Mustering</span><input type="checkbox"/></div>
					</div>
					<div className='specific-group-management-btn-field'><ButtonField label="Save"/></div>
				</div>
			:null}

			{openModal ? 
				<AddGroups 
					data={modalProps} 
					onCancel={(e) => {
						fetchGroup()
						setOpenModal(false)
					}} 
				/>
			:null}
		</div>
	)
}

export default ViewGroup
