import moment from 'moment-timezone';
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import * as API from "../../common/api/index";
import URL from "../../common/api/constantURL";
import { setSnackData } from "../../redux/action/userAction";
import * as functions from "../../common/functions/validateFunctions";

function AddFacility(props) {
	const dispatch = useDispatch()
	const zones = moment.tz.names()
	const accounts = useSelector(state => state.accountReducer.accounts)

	const [propsData, setPropsData] = useState(props.data);
	const [errorMessage, setErrorMessage] = useState(false);
	
	const fileUpload = useRef(null);
	const fileUpload2 = useRef(null);
	
	const [image, setImage] = useState("");
	const [facilityImageError, setFacilityImageError] = useState("");

	const [image2, setImage2] = useState("");
	const [facilityImageError2, setFacilityImageError2] = useState("");
	
	const [account, setAccount] = useState("");
	const [accountError, setAccountError] = useState(false);

	const [facilityName, setFacilityName] = useState("");
	const [facilityNameError, setFacilityNameError] = useState(false);

	const [facilityAddress, setFacilityAddress] = useState("");
	const [facilityAddressError, setFacilityAddressError] = useState(false);

	const [facilityCity, setFacilityCity] = useState("");
	const [facilityCityError, setFacilityCityError] = useState(false);

	const [facilityZipCode, setFacilityZipCode] = useState("");
	const [facilityZipCodeError, setFacilityZipCodeError] = useState(false);

	const [facilityCountry, setFacilityCountry] = useState("");
	const [facilityCountryError, setFacilityCountryError] = useState(false);

	const [timeZone, setTimeZone] = useState("");
	const [timeZoneError, setTimeZoneError] = useState(false);

	const [facilityContactNumber, setFacilityContactNumber] = useState("");
	const [facilityContactNumberError, setFacilityContactNumberError] = useState(false);

	const [facilityUsersCount, setFacilityUsersCount] = useState("");
	const [facilityUsersCountError, setFacilityUsersCountError] = useState(false);

	useEffect(() => {
		if (propsData.id) {
			setAccount(propsData.accountId || "")
			setFacilityName(propsData.siteName || "")
			setFacilityAddress(propsData.address || "")
			setFacilityCity(propsData.city || "")
			setFacilityZipCode(propsData.zipCode || "")
			setFacilityCountry(propsData.countryCode || "")
			setFacilityContactNumber(propsData.contact || "")
			setTimeZone(propsData.timezone || "")
			setFacilityUsersCount(propsData.userLicenseLimit || "")
			setImage(propsData.siteLogo || "")
			setImage2(propsData.mobileLogo || "")
		}
	}, []); 

	const createFacility = async () =>{
		let accountValidation = functions.validateInput(account, setAccountError, "numeric"),
			facilityNameValidation = functions.validateInput(facilityName, setFacilityNameError, "alphanumeric"),
			facilityAddressValidation = functions.validateInput(facilityAddress, setFacilityAddressError, "all"),
			facilityCityValidation = functions.validateInput(facilityCity, setFacilityCityError, "alphanumeric", false),
			facilityZipCodeValidation = functions.validateInput(facilityZipCode, setFacilityZipCodeError, "alphanumeric", false),
			facilityCountryValidation = functions.validateInput(facilityCountry, setFacilityCountryError, "alphanumeric", false),
			facilityTimeZoneValidation = functions.validateInput(timeZone, setTimeZoneError, "timezone"),
			facilityContactNumberValidation = functions.validateInput(facilityContactNumber, setFacilityContactNumberError, "numeric"),
			facilityUserCountValidation = functions.validateInput(facilityUsersCount, setFacilityUsersCountError, "numeric",false );

		let imageValidation  = true;
		let imageValidation2 = true;

		if (fileUpload.current.files.length <= 0) {
			if (!propsData.id || image === "") {
				setFacilityImageError("Required field");
				imageValidation = false;
			}
			
		}

		if (fileUpload2.current.files.length <= 0) {
			if (!propsData.id || image2 === "") {
				setFacilityImageError2("Required field");
				imageValidation2 = false;
			}	
		}

		if (accountValidation &&
			facilityNameValidation &&
			facilityAddressValidation &&
			facilityCityValidation &&
			facilityZipCodeValidation &&
			facilityCountryValidation &&
			facilityContactNumberValidation &&
			facilityTimeZoneValidation &&
			facilityUserCountValidation &&
			imageValidation &&
			imageValidation2 
		) {
			let requestData = {
				"accountId": account,
				"siteName" : facilityName,
				"address": facilityAddress,
				"city": facilityCity,
				"zipCode": facilityZipCode,
				"countryCode": facilityCountry,
				"timezone":timeZone,
				// "phone": facilityContactNumber,
				"contact": facilityContactNumber,
				"userLicenseLimit": facilityUsersCount,
			}
			var form_data = new FormData();
			for (var key in requestData) {
				form_data.append(key, requestData[key])
			}
			if (fileUpload.current.files.length > 0) {
				form_data.append("siteLogo", fileUpload.current.files.length > 0 ? fileUpload.current.files[0] : "");
			}
			if (fileUpload2.current.files.length > 0) {
				form_data.append("mobileLogo", fileUpload2.current.files.length > 0 ? fileUpload2.current.files[0] : "");
			}

			let response = null;
			if (propsData.id) {
				form_data.append("id", propsData.id);
				response = await API.putwithFileAPI(URL.facility, form_data);
			} else {
				response = await API.postWithFileAPI(URL.facility, form_data);
			}
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
					props.onCancel()
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else if (response.fetchStatus === "failure") {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}
	const handleFileChange = (fileref) => {
		if (fileref === fileUpload)
			setFacilityImageError("")
		if (fileref === fileUpload2)
			setFacilityImageError2("")
		let validFileExtensions = ["image/jpg", "image/jpeg", "image/png"];
		if (fileref.current.value != "") {
			if (validFileExtensions.includes(fileref.current.files[0].type)) {
				var file = fileref.current.files[0];
				var reader = new FileReader();
				var url = reader.readAsDataURL(file);
				reader.onloadend = function (e) {
					var file = fileref.current.files[0];
					const fileSize = file.size / 1024 / 1024;
					if (fileSize > 3) {
						fileref.current.value = "";
						if(fileref === fileUpload){
							fileref.current.files = new DataTransfer().files;
							setImage("")
							return setFacilityImageError("Please upload a file smaller than 3 MB.");
						}
						if(fileref === fileUpload2){
							fileref.current.files = new DataTransfer().files;
							setImage2("")
							return setFacilityImageError2("Please upload a file smaller than 3 MB.");
						}
					}
					if (fileref === fileUpload)
						setImage(reader.result)
					if (fileref === fileUpload2){
						setImage2(reader.result)
						// var image = new Image();
						// image.src = reader.result;
						// image.onload = function () {
						// 	var height = this.height;
						// 	var width = this.width;
						// 	if (height === width) {
						// 		setImage2(reader.result)
						// 	} else {
						// 		fileUpload2.current.files = new DataTransfer().files;
						// 		setFacilityImageError2("Please select image with equal height and width");
						// 		setImage2("")
						// 	}
						// };	
					}
				};
			} else {
				fileref.current.value = "";
				if (fileref === fileUpload)
					setFacilityImageError("Upload file with JPG, PNG, JPEG format");
				if (fileref === fileUpload2)
					setFacilityImageError2("Upload file with JPG, PNG, JPEG format");
			}
		}
	}
	return (
		<div className="modal">
			<div className="modal-content-container add-facility-modal" data-testid="add-facility-modal">
				<div className="modal-content ">
					<div className="modal-title">
						{propsData.id ?
							"Update Facility"
							:
							"Add Facility"
						}
					</div>
					<div className='modal-inner-container'>
						<div className='response-container'>{errorMessage}</div>
						<div className='facility-form-container'>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Account Name *</label>
								</div>
								<div className='facility-input-container'>
									<select
									    data-testid="account-name-select"
										autoFocus
										value={account}
										className={("facility-input ") + (accountError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setAccount, setAccountError)}
									>
										<option>Select Account</option>
										{accounts&&accounts.map(account =>
											<option value={account.id} key={account.id}>{account.accountName}</option>
										)}
									</select>
									<div className='input-error-message' data-testid="account-name-error">{accountError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Facility Name *</label>
								</div>
								<div className='facility-input-container'>
									<input 
										data-testid="facility-name-input"
										value={facilityName}
										className={("facility-input ") + (facilityNameError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityName, setFacilityNameError)}
									/>
									<div className='input-error-message' data-testid="facility-name-error" >{facilityNameError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Address *</label>
								</div>
								<div className='facility-input-container'>
									<input 
									    data-testid="facility-address-input"
										value={facilityAddress}
										className={("facility-input ") + (facilityAddressError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityAddress, setFacilityAddressError)}
									/>
									<div className='input-error-message' data-testid="facility-address-error">{facilityAddressError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>City</label>
								</div>
								<div className='facility-input-container'>
									<input
										data-testid="facility-city-input"
										value={facilityCity}
										className={("facility-input ") + (facilityCityError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityCity, setFacilityCityError)}
									/>
									<div className='input-error-message'data-testid="facility-city-error" >{facilityCityError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>ZIP Code</label>
								</div>
								<div className='facility-input-container'>
									<input 
									    data-testid="facility-zipcode-input"
										value={facilityZipCode}
										className={("facility-input ") + (facilityZipCodeError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityZipCode, setFacilityZipCodeError)}
									/>
									<div className='input-error-message' data-testid="facility-zipcode-error">{facilityZipCodeError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Country</label>
								</div>
								<div className='facility-input-container'>
									<input 
										data-testid="facility-country-input"
										value={facilityCountry}
										className={("facility-input ") + (facilityCountryError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityCountry, setFacilityCountryError)}
									/>
									<div className='input-error-message' data-testid="facility-country-error" >{facilityCountryError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Timezone *</label>
								</div>
								<div className='facility-input-container'>
									<select
										data-testid="facility-timezone-select"
										autoFocus
										value={timeZone}
										className={("facility-input ") + (timeZoneError ? " invalid " : "")}
										onChange={(event) => { return functions.validateInputChange(event, setTimeZone, setTimeZoneError)}}
									>
										<option>Select Timezone</option>
										{zones && zones.map((zone, index) =>
											<option value={zone} key={index}>{zone}</option>
										)}
									</select>
									<div className='input-error-message' data-testid="facility-timezone-error" >{timeZoneError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Contact Number *</label>
								</div>
								<div className='facility-input-container'>
									<input 
										data-testid="facility-contact-input"
										value={facilityContactNumber}
										className={("facility-input ") + (facilityContactNumberError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityContactNumber, setFacilityContactNumberError)}
									/>
									<div className='input-error-message' data-testid="facility-contact-error">{facilityContactNumberError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Total Accounts</label>
								</div>
								<div className='facility-input-container'>
									<input
										data-testid="facility-total-account-input"
										value={facilityUsersCount}
										className={("facility-input ") + (facilityUsersCountError ? " invalid " : "")}
										onChange={(event) => functions.validateInputChange(event, setFacilityUsersCount, setFacilityUsersCountError)}
									/>
									<div className='input-error-message' data-testid="facility-totaol-account-error">{facilityUsersCountError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Web Logo *</label>
								</div>
								<div className='facility-input-container'>
									<input 
										data-testid="facility-logo-input"
										type="file" 
										accept="image/*" 
										ref={fileUpload} 
										className={("facility-input ") + (facilityImageError ? " invalid " : "")}
										onChange={() => handleFileChange(fileUpload)} 
									/>
									{image ?
										<img className="facility-image-view" src={image} />
									:null}
									<div className='input-error-message' data-testid="facility-logo-error">{facilityImageError}</div>
								</div>
							</div>
							<div className='facility-label-input-container'>
								<div className='facility-label-container'>
									<label>Mobile Logo *</label>
								</div>
								<div className='facility-input-container'>
									<input 
										data-testid="facility-mobile-logo-input"
										type="file" 
										accept="image/*" 
										ref={fileUpload2} 
										className={("facility-input ") + (facilityImageError2 ? " invalid " : "")}
										onChange={() => handleFileChange(fileUpload2)} 
									/>
									{image2 ?
										<img className="facility-image-view" src={image2} />
									:null}
									<div className='input-error-message' data-testid="facility-mobile-logo-error">{facilityImageError2}</div>
								</div>
							</div>
						</div>
						<div className="popup-button-container">
							<button data-testid="add-facility-modal-close-button" className="cancel-button" onClick={(e) => props.onCancel()}>Cancel</button>
							<div class="vertical_line" ></div>
							<button data-testid="add-facility-modal-submit-button" className="save-button" onClick={() => createFacility()} >
								{propsData.id ?
									"Update Facility"
									:
									"Add Facility"
								}
							</button>
						</div >
					</div>
				</div>
			</div>
		</div>
	)
}
export default AddFacility;
