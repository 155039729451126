import { formatUserName } from '../functions/userNameFormat';

export const makeid = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() *
			charactersLength));
	}
	return result;
}

export const getRoleName = (userListTypes, roleIds) => {
	let roleNameList = []
	if (roleIds.length > 0) {
		roleIds.map((id) => {
			userListTypes.map((data) => {
				if (data.id === id) {
					return roleNameList.push(data.roleName)
				}
			})
		})
		return roleNameList.join(", ")
	}
	return "-"
}

export function cardFilter(item, search) {
	let targetItem;
	if (item.groupName) {
		targetItem = item.groupName
	} else if (item.firstName) {
		targetItem = item.firstName + item.lastName
	} else if (item.item) {
		targetItem = item.item
	} else if (item.roleName) {
		targetItem = item.roleName
	} else if (item.deviceName) {
		targetItem = item.deviceName
	}
	if (search == "") {
		return item
	} else {
		if (targetItem.toLowerCase().includes(search.toLowerCase())) {
			return targetItem;
		} else if (item.firstName || item.lastName) {
			if (formatUserName(item).toLowerCase().includes(search.toLowerCase())) {
				return item;
			}
		}
	}
}

export function downloadImage(blob, filename) {
    const imageUrl = URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = imageUrl;
    downloadLink.download = filename; // Ensure the filename includes the appropriate extension
    downloadLink.click();
    URL.revokeObjectURL(imageUrl);
}