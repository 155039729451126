import React, { useState } from 'react'
import "./ManageDevices.css"
import { useDispatch, useSelector } from 'react-redux';
import { setAlarms,  } from '../../../redux/action/alarmAction';
import { setGroups } from '../../../redux/action/groupAction';
import { setLoading } from '../../../redux/action/userAction';
import { setSnackData} from "../../../redux/action/userAction";
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import Card from '../../../common/component/Card';
import {BsArrowRight} from 'react-icons/bs';
import {BsArrowLeft} from 'react-icons/bs';
import * as functions from "../../../common/functions/validateFunctions";
import { setDevices } from '../../../redux/action/deviceAction';
import Confirmation from "../../../common/component/Confirmation"
import { useEffect } from 'react';
function ManageDevices(props) {
	const dispatch = useDispatch()
	const [propsData, setPropsData] = useState(props.data);
	const alarms = useSelector(state => state.alarmReducer.alarmsByFacilityId)
    const groups = useSelector(state => state.groupReducer.groups)
	const [unAssignedAlarmList, setUnAssignedAlarmList] = useState([])
	const [assignedAlarmList, setAssignedAlarmList] = useState([])
	const [selectedAlarm, setSelectedAlarm] = useState([])
	const [alarmAssignedSelectAll, setAlarmAssignedSelectAll] = useState(false)
	const [alarmUnAssignedSelectAll, setAlarmUnAssignedSelectAll] = useState(false)
	const [unAssignedGroups,setUnAssignedGroups] =  useState([])
	const [assignedGroups,setAssignedGroups] =  useState([])
	const [selectedGroups,setSelectedGroups] = useState([])
	const [assignedSelectAllCheckBox,setAssinedSelectAllCheckBox] = useState(false)
	const [unassignedSelectAllCheckBox, setUnassinedSelectAllCheckBox] = useState(false)
	var [errorMessage, setErrorMessage] = useState("")
	const [confirmation, setConfirmation] = useState(false);
	var [validDeviceId, setValidDeviceId] = useState(true);
	var [validDeviceName, setValidDeviceName] = useState(true);
	var [validPhoneNumber, setValidPhoneNumber] = useState(true);
	var [validEditDeviceForm, setValidEditDeviceForm] = useState(true)
	const [Device, setDevice] = useState({
		deviceId: "",
		deviceName: "",
		phoneNumber: "",
		groupIds :[],
		alarmButtonIds:[],
		id:""
	})
	useEffect(()=>{
		fetchAlarms()
	}, [])

	useEffect(() => {
		if(propsData.deviceId){
			let devicedata = {
					deviceId: propsData.deviceId ||"",
					deviceName: propsData.deviceName || "",
					phoneNumber: propsData.phoneNumber || "",
					groupIds:propsData.groupIds || [],
					alarmButtonIds: propsData.alarmButtonIds || [],
					isRegistered:propsData.isRegistered,
					id:propsData.id,
					saraRegistrationStatus:propsData.saraRegistrationStatus
			}
			setDevice({ ...devicedata })
		}
			let tempAssigenedGroup = [];
		let tempUnAssignedGroup = [];
		groups && groups.map((data) => {
			if (Device.groupIds && Device.groupIds.includes(data.id)) {
				tempAssigenedGroup.push(data)
			} else {

				tempUnAssignedGroup.push(data)
			}

		})
		setUnAssignedGroups(tempUnAssignedGroup)
		setAssignedGroups(tempAssigenedGroup)

		let tempAssigenedAl = [];
		let tempUnAssignedAl= [];
		alarms && alarms.map((data) => {
			if (Device.alarmButtonIds && Device.alarmButtonIds.includes(data.id)) {
				tempAssigenedAl.push({id: data.id, item: data.buttonName})
			} else {

				tempUnAssignedAl.push({id: data.id, item: data.buttonName})
			}

		})
		setUnAssignedAlarmList(tempUnAssignedAl)
		setAssignedAlarmList(tempAssigenedAl)
		
	}, [groups,alarms,propsData]);

	const selectAlarm = (alarmIds) => {
		setSelectedAlarm([...alarmIds])
	}
    const selectAlarmMobile=(data)=>{
		let tempSelectedAlarm = selectedAlarm;
		let index = tempSelectedAlarm.indexOf(data)
		if (index > -1) {
			tempSelectedAlarm.splice(index, 1);
		}else{
			tempSelectedAlarm.push(data)
		}
		setSelectedAlarm([...tempSelectedAlarm])
	}
    const selectAllAlarm =(checkStatus,data)=>{
		if(checkStatus === true){
			let tempIds = (data.map((data)=>{
				return (data.id)
			}))
			setSelectedAlarm([...tempIds])
		}else{
			setSelectedAlarm([])
		}
	}
    const moveSelectedAlarm = () => {
		setAlarmUnAssignedSelectAll(false)
		let tempAssignedValues = assignedAlarmList
		let tempUnAssignedValues = unAssignedAlarmList
		let tempSelectedAlarm = selectedAlarm;
		alarms.map((alarm, index) => {
			if (selectedAlarm.includes(alarm.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(alarm.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === alarm.id), 1);
				tempAssignedValues.push({id:alarm.id, item: alarm.buttonName})
				tempSelectedAlarm.splice(tempSelectedAlarm.indexOf(alarm.id), 1);
			}
		})
		setSelectedAlarm ([...tempSelectedAlarm])
		setAssignedAlarmList([...tempAssignedValues])
		setUnAssignedAlarmList([...tempUnAssignedValues])
	}
    const moveUnSelectedAlarm = () => {
		setAlarmAssignedSelectAll(false)
		let tempAssignedValues = assignedAlarmList
		let tempUnAssignedValues = unAssignedAlarmList
		let tempSelectedAlarm = selectedAlarm;
		alarms.map((alarm) => {
			if (selectedAlarm.includes(alarm.id) && tempAssignedValues.map((data)=>{return data.id}).includes(alarm.id)) {
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === alarm.id), 1);
				tempUnAssignedValues.push({id:alarm.id, item: alarm.buttonName})
				tempSelectedAlarm.splice(tempSelectedAlarm.indexOf(alarm.id), 1);
			}
		})
		setSelectedAlarm ([...tempSelectedAlarm])
		setAssignedAlarmList([...tempAssignedValues])
		setUnAssignedAlarmList([...tempUnAssignedValues])
	}
	const selectGroup = (groupId) => {
		setSelectedGroups([...groupId])
	}
	const selectGroupMobile = (groupId) => {
		let tempSelectedGroups = selectedGroups;
		let index = tempSelectedGroups.indexOf(groupId)
		if (index > -1) {
			tempSelectedGroups.splice(index, 1);
		} else {
			tempSelectedGroups.push(groupId)
		}
		setSelectedGroups([...tempSelectedGroups])
	}
    const selectAllGroups = (checkStatus,group) => {
		if (checkStatus === true) {
			let tempIds = (group.map((data)=>{
                return (data.id)
            }))
			setSelectedGroups([...tempIds])
		} else {
			setSelectedGroups([])
		}
	}
	const moveUnSelectedGroups = () => {
        setAssinedSelectAllCheckBox(false)
		let tempAssignedValues = assignedGroups
		let tempUnAssignedValues = unAssignedGroups
		let tempSelectedUsers = selectedGroups;
		groups.map((user) => {
			if (selectedGroups.includes(user.id) && tempAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
				tempUnAssignedValues.push(user)
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedGroups([...tempSelectedUsers])
		setAssignedGroups([...tempAssignedValues])
		setUnAssignedGroups([...tempUnAssignedValues])
	}
	const moveSelectedGroups= () => {
        setUnassinedSelectAllCheckBox(false)
		let tempAssignedValues = assignedGroups
		let tempUnAssignedValues = unAssignedGroups
		let tempSelectedUsers = selectedGroups;
		groups.map((user) => {
			if (selectedGroups.includes(user.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
				tempAssignedValues.push(user)
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedGroups([...tempSelectedUsers])
		setAssignedGroups([...tempAssignedValues])
		setUnAssignedGroups([...tempUnAssignedValues])
	}
	const fetchAlarms = async () => {
		let url = URL.alarmsByFacilityId + "?facilityId=" + localStorage.getItem("facilityId");
		
		// if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
		// 	url = URL.alarmsByFacilityId
		// }
		let response = await API.getAPI(url)
		
		fetchGroup()
		let alarmList = response.result.result;
		dispatch(setAlarms(alarmList))
	}
	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		// if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
		// 	url = URL.groups
		// }
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		let groupList = response.result.result
		for (let i = 0; i < groupList.length; i++) {
			groupList[i].userCount = groupList[i].userIds.length
			if (groupList[i].isEventGroup) {
				groupList.splice(i,1)
				i--
			}
		}
		dispatch(setGroups(groupList))
	}
	const InputHandler = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		const updatedForm = {
			...Device,
			[name]: value,
		};
		setDevice(updatedForm);
	}
	
	const formSubmitHandler = async (event) => {
		event.preventDefault();
		setValidDeviceId(true)
		setValidDeviceName(true)
		setValidPhoneNumber(true)
		if (!functions.validateInput(Device.deviceId.trim(), setErrorMessage, "alphanumeric")) {
			setValidEditDeviceForm(false)
			return setValidDeviceId(false)
		}
		if (!functions.validateInput(Device.phoneNumber.trim(), setErrorMessage, "nonalphabet",false)) {
			setValidEditDeviceForm(false)
			return setValidPhoneNumber(false)
		}
		if (!functions.validateInput(Device.deviceName.trim(), setErrorMessage)) {
			setValidEditDeviceForm(false)
			return setValidDeviceName(false)
		}
		if (Device.deviceId.trim().length < 4 ||  Device.deviceId.length >25) {
			setErrorMessage("Device Id should be of minimum length 4 and maximum length of 25")
			setValidEditDeviceForm(false)
			return setValidDeviceId(false)
		}
		if ( Device.deviceName.trim().length < 4 || Device.deviceName.length>25) {
			setValidEditDeviceForm(false)
			setErrorMessage("Device Name should be of minimum length 4 and maximum length of 25")
			return setValidDeviceName(false)
		}
		if(Device.phoneNumber.trim().length >25){
			setErrorMessage("Phone number should be of maximum length of 25")
			setValidEditDeviceForm(false)
			return setValidPhoneNumber(false)
		}
		let assignedGroupArray = []

		if (assignedGroups.length > 0) {
			assignedGroups.map((data) => {
				assignedGroupArray.push(data.id)
			})
		}
		let assignedAlarmArray = []
		if (assignedAlarmList.length > 0) {
			assignedAlarmList.map((data) => {
				assignedAlarmArray.push(data.id)
			})
		}
		if (validDeviceId && validDeviceName && validPhoneNumber) {
			let data = {
				"facilityId": localStorage.getItem("facilityId"),
				"deviceId": Device.deviceId.trim(),
				"deviceName": Device.deviceName.trim(),
				"phoneNumber": Device.phoneNumber.trim(),
				"alarmButtonIds":assignedAlarmArray,
				"groupIds": assignedGroupArray
			}

			dispatch(setLoading(true))
			let response = null
			if (propsData.id) {
				data = {
				...data,
				"id" : Device.id
				}
				response = await API.putAPI(URL.deviceEdit, data);
			} else {
				response = await API.postAPI(URL.deviceAdd, data);
			}
			dispatch(setLoading(false))
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					props.onCancel()
					dispatch(setSnackData(snackData))
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
	}
	// function handleDetachh(deviceList){
	// 	deviceList.map((device)=>{
	// 		if(device.id===propsData.id){
	// 			setPropsData(device)
	// 		}
	// 	})	
	// }
	// const fetchDevice = async () => {
	// 	let url = URL.devices + "?facilityId=" + localStorage.getItem("facilityId");
	// 	let response = await API.getAPI(url)
	// 	dispatch(setLoading(false))
	// 	if(response.fetchStatus === "success"){
	// 		let deviceList = response.result.result
	// 		if(response.result.status === "success"){
	// 			dispatch(setDevices(deviceList));
	// 			handleDetachh(deviceList)
	// 		}
	// 	}
	// }
	const handleDetach = async () => {
		if(Device.isRegistered){
			dispatch(setLoading(true))
			let response = null
			let data = {
				"facilityId": localStorage.getItem("facilityId"),
				"deviceId": Device.id,
			}
			response = await API.putAPI(URL.deviceDetach, data);
	
			dispatch(setLoading(false))
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					dispatch(setSnackData(snackData))
					props.onCancel()
					setConfirmation(false)
				} else {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down.Try again.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		}
		
	}
	return (
		<div className="modal" data-testid="add-device-popup">
			<div className="modal-content-container manage-device-modal">
				<div className="modal-content manage-device-modal-content ">
					<div className='manage-device-modal-inner-content'>
						<div className='manage-device-details-ctr'>
							<div className='manage-device-details-title' data-testid='manage-device-details-title'>{propsData.id ? `Editing Device ID ${propsData.deviceId}` : "Add Device"}</div>
							{propsData.id ? null :<div className={validDeviceId ? "" : "highLight"}>
								<label data-testid="device-id-label">Device ID *</label>
								<input  style={{ border:"2px solid #890804;"}} type='text' name="deviceId" data-testid="device-id-input"
										value={Device.deviceId}
										onChange={(event) => {
											setValidDeviceId(true)
											setValidEditDeviceForm(true)
											InputHandler(event)
										}}/>
							</div>}
							<div className={validDeviceName ? "" : "highLight"}>
								<label data-testid="device-name-label">Device Name *</label>
								<input data-testid="device-name-input" type='text' name="deviceName"
										value={Device.deviceName}
										onChange={(event) => {
											setValidDeviceName(true)
											setValidEditDeviceForm(true)
											InputHandler(event)
										}}/>
							</div>
							<div className={validPhoneNumber ? "" : "highLight"}>
								<label data-testid="phone-number-label">Cell Phone Number</label>
								<input  data-testid="phone-number-input" type='text' name="phoneNumber"
										value={Device.phoneNumber}
										onChange={(event) => {
											setValidPhoneNumber(true)
											setValidEditDeviceForm(true)
											InputHandler(event)
										}}/>
							</div>
							{/* <div>
								<label>Internal extension *</label>
								<input  type='text' />
							</div> */}
						{propsData.id ?<div>
								<label data-testid="device-resgister-label">Device Registered</label>
								<div data-testid="device-register-checkBox" className='detach-ctr'>
									<input type='checkbox' checked={Device.saraRegistrationStatus} disabled="true"/>
									<span>Yes</span>
									<span data-testid="detach-span" className={Device.isRegistered ? 'manage-device-detach-btn' : "detch-blocked"} onClick={()=>{ Device.isRegistered && setConfirmation(true)}}>Detach</span>
								</div>
							</div> : ""}
						</div> 
						<div className='manage-device-allocation-ctr'>
							<div className='manage-device-button-allocation-ctr'>
								<div className='device-allocation-title'>Assigned Alarm Buttons</div>
								<div className='device-allocation-sub-title'>Choose Alarms buttons to be associated with this device</div>
								<div className='alarm-card-container'>

										<Card
											header="select"
											type="alarm-list"
											title="Buttons"
											item={unAssignedAlarmList}
											selectedData = {(data)=>selectAlarm(data)}
											selectedDataMobile = {(data)=>{selectAlarmMobile(data)}}
											selectAll = {(checkStatus,data)=>{selectAllAlarm(checkStatus,data)}}
											setSelectAllStatus={(checkStatus)=>{setAlarmUnAssignedSelectAll(checkStatus);setAlarmAssignedSelectAll(false)}}
											checkedStatus={alarmUnAssignedSelectAll}
											selectionData={selectedAlarm}
											dataTestId={"unAssignedAlarmData"}
											selectAllDataTestId={"UnAssignedalarm-select-all"}
											searchDataTestId={"unAssigned-search-filter-input-box"}
										/>

									<div className="icon">
										<BsArrowRight onClick={()=>{moveSelectedAlarm()}} data-testid="add-unAssigned-alarm"/>
										<BsArrowLeft onClick={()=>{moveUnSelectedAlarm()}} data-testid="remove-Assigned-alarm"/>

									</div >

										<Card
											headers="deselect"
											type="alarm-list"
											title="Selected"
											item={assignedAlarmList}
											selectedData = {(data)=>selectAlarm(data)}
											selectedDataMobile = {(data)=>{selectAlarmMobile(data)}}
											selectionData={selectedAlarm}
											selectAll = {(checkStatus,data)=>{selectAllAlarm(checkStatus,data);}}
											setSelectAllStatus={(checkStatus)=>{setAlarmAssignedSelectAll(checkStatus);setAlarmUnAssignedSelectAll(false)}}
											checkedStatus={alarmAssignedSelectAll}
											dataTestId={"AssignedAlarmData"}
											selectAllDataTestId={"Assignedalarm-select-all"}
										/>

								</div>
							</div>
							<div className='manage-group-button-allocation-ctr'>
								<div className='device-allocation-title'>Assigned Groups</div>
								<div className='device-allocation-sub-title'>Choose Groups to be associated with this device</div>
								<div className='alarm-card-container'>
									<Card
										header="select"
										title="Groups"
										type="add-group"
										item={(unAssignedGroups)}
										selectedData = {(data)=>selectGroup(data)}
										selectAll = {(checkStatus,data)=>{selectAllGroups(checkStatus,data)}}
										selectedDataMobile = {(data)=>{selectGroupMobile(data)}}
										setSelectAllStatus={(checkStatus)=>{setUnassinedSelectAllCheckBox(checkStatus);setAssinedSelectAllCheckBox(false)}}
										checkedStatus={unassignedSelectAllCheckBox}
										selectionData={selectedGroups}
										dataTestId={"unassigned-group"}
										selectAllDataTestId={"unAssigned-checkbox-selectAll"}
										searchDataTestId={"unAssigned-search-filter-input-box"}
										cardContainerDataTestId={"group-card"}
									/>
									<div className='icon'>
										<BsArrowRight onClick={() => moveSelectedGroups()} id="move-unAssigned-group" data-testid="move-unAssigned-group"/>
										<BsArrowLeft onClick={() => moveUnSelectedGroups()} id="move-Assigned-group" data-testid="move-Assigned-group"/>
									</div>
									<Card

										type="add-group"
										title="Selected"
										item={(assignedGroups)}
										selectedData = {(data)=>selectGroup(data)}
										selectedDataMobile = {(data)=>{selectGroupMobile(data)}}
										selectAll = {(checkStatus,data)=>{selectAllGroups(checkStatus,data)}}
										setSelectAllStatus={(checkStatus)=>{setAssinedSelectAllCheckBox(checkStatus);setUnassinedSelectAllCheckBox(false)}}
										checkedStatus={assignedSelectAllCheckBox}
										selectionData={selectedGroups}
										dataTestId={"assigned-group-data"}
										selectAllDataTestId={"Assigned-checkbox-selectAll"}
										searchDataTestId={"unAssigned-search-filter-input-box"}
										cardContainerDataTestId={"group-card"}
									/>

								</div>
							</div>
						</div>
					</div>
					<div className='manage-device-btn-ctr'>
					{/* <ButtonField label="Cancel" type="button"  onClick={(event) => {
									event.preventDefault();
									navigate("/home/devices")

								}}  className="manage-device-cancel-btn" />
					<ButtonField label={updateFlag ? "Update" : "Save"} type="submit" className="manage-device-save-btn" onClick={formSubmitHandler} /> */}
						<button className="cancel-button" data-testid="manage-device-cancel-btn" onClick={props.onCancel}>Cancel</button>
                        <div class="vertical_line" ></div>
						<button className="save-button" data-testid="manage-device-save-btn" onClick={formSubmitHandler} >
                           {propsData.id ? "Update":"Save"}
                        </button>
					</div>
					<div data-testid="manage-device-error-msg" className="device-error-msg" >
							{!validEditDeviceForm  ? errorMessage: ""}
						</div>

				</div>
			</div>
			{confirmation ?
						<Confirmation deleteMessage ={"Are you sure you want to detach the device "+Device.deviceName+" ?"} successFunc={() => handleDetach()}  cancelFunc={() => setConfirmation(false)}  buttonValue="Detach"/>
					: ""}
		</div>

	)
}

export default ManageDevices
