import React from 'react'
import "./MusteringList.css"
import {BsFillTriangleFill} from "react-icons/bs"
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import { setCurrentMusteringEventUserList, setLoading, setSnackData } from '../../../redux/action/userAction';
function MusteringList(props) {
    const MusteringDetailsList = useSelector(state => state.userReducer.activeMusteringList)
    const dispatch = useDispatch()
    var yesterdayDateObj = new Date();
    // subtract one day from current time                        
    yesterdayDateObj.setDate(yesterdayDateObj.getDate() - 1);

    const fetchSpecificMusteringDetails = async(data) =>{
        dispatch(setLoading(true))
        let url = URL.specificMusteringEventUsers +"?alertId=" + data.id;
		let response = await API.getAPI(url)
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
                props.setViewFlag(3)
                dispatch(setCurrentMusteringEventUserList(response.result.result))	
			}else{
                props.setViewFlag(3)
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
                dispatch(setLoading(false))
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
            props.setViewFlag(3)
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
            dispatch(setLoading(false))
			dispatch(setSnackData(snackData))
		}
    }

    return (
        <React.Fragment>
            <div className='list-page-title-div'>
                <div className='list-page-title' data-testid="mustering-dashboard-title">Mustering Dashboard</div>
            </div>
            <div className='mustering-details-container'>
                {
                    MusteringDetailsList.map((musteringData)=>{
                        return <div className='mustering-details-card' data-testid="mustering-details-card">
                                    <h2 className='mustering-card-title' data-testid="mustering-card-title">{musteringData.title}</h2>
                                    <div className='mustering-card-icon-details-container'>
                                        <div className='mustering-card-icon-container'>
                                            <BsFillTriangleFill className='mustering-triangle-icon' style={{
                                                fill: musteringData.alertColor ?  musteringData.alertColor : '#d58c40',
                                            }}/>
                                            <img src={musteringData.alertIcon} className='mustering-alert-icon' alt="Alert Icon"/>
                                        </div>
                                    </div>
                                    <div className="mustering-card-description" data-testid="mustering-card-description">{musteringData.description}</div>
                                    <div className='mustering-triggered-time' data-testid="mustering-triggered-time">
                                    { 
                                        (moment(musteringData.alertCreatedTime).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD") ? "Today"
                                        : (moment(musteringData.alertCreatedTime).format("YYYY-MM-DD") === moment(yesterdayDateObj).format("YYYY-MM-DD")) ? "Yesterday"
                                        : moment(musteringData.alertCreatedTime).format("DD-MMM-YYYY") )
                                        + " at " 
                                        + moment(musteringData.alertCreatedTime).format("hh:mm A")
                                    }
                                    </div>
                                    <div className="large-button " onClick={(e)=>{
                                        e.preventDefault()
                                        fetchSpecificMusteringDetails(musteringData)
                                    }}
                                    data-testid="view-mustering-status-button"
                                    >View Mustering Status</div>
                                </div>           
                    })
                }
            </div>
            
        
            
        </React.Fragment>
    )
}

export default MusteringList
