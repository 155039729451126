import { useEffect, useState } from 'react';
import SearchIcon from '../../assets/svg/event_history_search_icon.svg'
import '../style/Card.css';
import { defaultPreference, separatorPreference } from '../variables/preference'
import { formatUserName } from '../functions/userNameFormat';
import { cardFilter } from '../functions/commonFunctions';

function Card(props) {
    const [listItem, setListItem] = useState(props.item);
    const [search, setSearch] = useState("");
    const {
        selectedData,
        selectionData,
        selectAll,
        checkedStatus,
        setSelectAllStatus,
        dataTestId,
        selectAllDataTestId,
        searchDataTestId,
        cardContainerDataTestId,
        selectedDataMobile,
    } = props;
    useEffect(() => {
        if (props.type === "add-users-group") {
            let unSorted = [...props.item];
            unSorted.sort((a, b) => {
                let a_fullname = formatUserName(
                    a,
                    defaultPreference,
                    separatorPreference.Space
                );
                let b_fullname = formatUserName(
                    b,
                    defaultPreference,
                    separatorPreference.Space
                );
                return a_fullname.localeCompare(b_fullname);
            });

            setListItem(unSorted);
        } else if (props.type === "add-group") {
            let unSortedArr = [...props.item];
            if (props.header == "select") {
                unSortedArr.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));
            }
            setListItem(unSortedArr);
        } else if (props.type === "add-role") {
            let unSortedArr = [...props.item];
            if (props.header == "select") {
                unSortedArr.sort((a, b) => (a.roleName > b.roleName ? 1 : -1));
            }
            setListItem(unSortedArr);
        } else if(props.type === "add-device"){
            let unSortedArr = [...props.item];
            if (props.header == "select") {
                unSortedArr.sort((a, b) => (a.deviceName > b.deviceName ? 1 : -1));
            }
            setListItem(unSortedArr);
        }else {
            setListItem(props.item);
        }
    }, [props.item]);

    function chooseAll(e){
        setSelectAllStatus(e.target.checked);
        let data = listItem.filter((item) => { 
            return cardFilter(item, search);
        })
        selectAll(e.target.checked,data);
    }

    return (
        <div className="card-container" data-testid={cardContainerDataTestId}>
            <div className="item-listing-box">
                <div
                    className={
                        props.header == "select"
                            ? "card-container-head select-header"
                            : "card-container-head deselect-header"
                    }
                >
                    <div
                        className="card-container-title"
                        data-testid="card-container-title"
                    >
                        {props.title}
                    </div>
                    <div
                        className={
                            props.header == "select" ? "card-select-box" : "card-select-box"
                        }
                    >
                        <label data-testid="choose-all-checkbox">Choose All</label>
                        <input
                            type="checkbox"
                            data-testid={selectAllDataTestId}
                            id={selectAllDataTestId}
                            checked={checkedStatus}
                            onChange={(e) => {
                                chooseAll(e)
                            }}
                            className={
                                props.header == "select"
                                    ? "select-box-color"
                                    : "deselect-box-color"
                            }
                        />
                    </div>
                </div>
                {props.header == "select" ? (
                    <div className="card-filter">
                        <div className="filter-container">
                            <img src={SearchIcon} />
                            <input
                                type="search"
                                className="card-search"
                                data-testid={searchDataTestId}
                                id={searchDataTestId}
                                placeholder="Filter"
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </div>
                    </div>
                ) : null}
                <select
                    className={
                        props.header == "select"
                            ? "card-data-list"
                            : "filter-card-data-list"
                    }
                    data-testid="select-card-data-list"
                    multiple
                    onChange={(e) => {
                        let value = Array.from(
                            e.target.selectedOptions,
                            (option) => option.value
                        );
                        selectedData([]);
                        let tempDataList = [];
                        value.map((data) => {
                            tempDataList.push(parseInt(data));
                        });
                        setSelectAllStatus(false);
                        selectedData(tempDataList);
                    }}
                >
                    {listItem
                        ? listItem
                            .filter((item) => {
                                return cardFilter(item, search);
                            })
                            .map((d, index) => (
                                <option
                                    data-role="none"
                                    data-native-menu="false"
                                    key={index}
                                    selected={selectionData && selectionData.includes(d.id)}
                                    data-testid={dataTestId}
                                    id={dataTestId}
                                    className={`user-list-row ${selectionData && selectionData.includes(d.id)
                                            ? "selected-group"
                                            : ""
                                        }`}
                                    value={d.id}
                                >
                                    {props.type === "add-users-group"
                                        ? formatUserName(d)
                                        : props.type === "add-group"
                                            ? d.groupName
                                            : props.type === "users-role"
                                                ? d.roleName
                                                : props.type === "add-role"
                                                    ? d.roleName
                                                        :props.type === "add-device"
                                                            ? d.deviceName
                                                                : d.item
                                                        
                                                    }
                                </option>
                            ))
                        : null}
                </select>
                <div
                    className={
                        props.header == "select"
                            ? "card-data-list card-list-mobile"
                            : "filter-card-data-list filter-list-mobile"
                    }
                >
                    {listItem
                        ? listItem
                            .filter((item) => {
                                return cardFilter(item, search);
                            })
                            .map((d, index) => (
                                <div
                                    key={index}
                                    data-testid={"mobile-" + dataTestId}
                                    id={dataTestId}
                                    className={`user-list-row ${selectionData && selectionData.includes(d.id)
                                            ? "selected-mobile-group"
                                            : ""
                                        }`}
                                    value={d.id}
                                    onClick={() => {
                                        selectedDataMobile(d.id);
                                        setSelectAllStatus(false);
                                    }}
                                >
                                    {props.type === "add-users-group"
                                        ? formatUserName(d)
                                        : props.type === "add-group"
                                            ? d.groupName
                                            : props.type === "users-role"
                                                ? d.roleName
                                                : props.type === "add-role"
                                                    ? d.roleName
                                                        :props.type === "add-device"
                                                            ? d.deviceName
                                                                : d.item
                                    }
                                </div>
                            ))
                        : null}
                </div>
            </div>
        </div>
    );
}
export default Card;
