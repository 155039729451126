import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import addUser from '../../../assets/svg/add_user_to_group.svg';
import removeUser from '../../../assets/svg/remove_user_from_group.svg';
import { setSnackData, setLoading } from '../../../redux/action/userAction';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import '../../../common/style/style.css';
import './AddGroups.css'
import * as functions from "../../../common/functions/validateFunctions";
import { setGroups } from '../../../redux/action/groupAction';
import Card from '../../../common/component/Card';
import {BsArrowRight} from 'react-icons/bs';
import {BsArrowLeft} from 'react-icons/bs';
import {BiCommand} from "react-icons/bi"


function AddGroups(props) {
	const dispatch = useDispatch();
	const users = useSelector(state => state.userReducer.users)
	const devices = useSelector(state => state.deviceReducer.devices)
	const userTypes = useSelector(state => state.userReducer.userTypes)
	const [role,setRole]=useState('');
	const [groupName, setGroupName] = useState("");
	const [groupNameError, setGroupNameError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	const [propsData, setPropsData] = useState(props.data);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedDevices, setSelectedDevices] = useState([]);

	const [search, setSearch] = useState('');
	const [assignedUsers, setAssignedUsers] = useState([]);
	const [unAssignedUsers, setUnAssignedUsers] = useState([]);

	const [assignedDevices, setAssignedDevices] = useState([]);
	const [unAssignedDevices, setUnAssignedDevices] = useState([]);
	
	const [unassignedUserSelectAllCheckBox,setUnassinedUserSelectAllCheckBox] = useState(false)
	const [unassignedDeviceSelectAllCheckBox,setUnassinedDeviceSelectAllCheckBox] = useState(false)

	const [assignedUserSelectAllCheckBox,setAssinedUserSelectAllCheckBox] = useState(false)
	const [assignedDeviceSelectAllCheckBox,setAssinedDeviceSelectAllCheckBox] = useState(false)

	const [showUsersCard, setShowUsersCard] = useState(true)
    const [showDevicesCard, setShowDevicesCard] = useState(false)
	const deploymentConfiguration = useSelector(state => state.deployementReducer.deployementConfiguration)
	const [isDeviceBasedAlert,setIsDeviceBasedAlert] = useState(false)
	useEffect(() => {
		if (propsData.groupName){
			setGroupName(propsData.groupName)
		}
		let tempUnAssignedUsers = []
		let assignedUsers = []
		users.map((user)=>{
			if (propsData.userIds && propsData.userIds.includes(user.id)) {
				assignedUsers.push(user)
			}else{
				tempUnAssignedUsers.push(user)
			}
		})
		setUnAssignedUsers(tempUnAssignedUsers)
		setAssignedUsers(assignedUsers)

		let tempUnAssignedDevices = []
		let assignedDevices = []
		devices.map((device)=>{
			if (propsData.deviceIds && propsData.deviceIds.includes(device.id)) {
				assignedDevices.push(device)
			}else{
				tempUnAssignedDevices.push(device)
			}
		})
		setUnAssignedDevices(tempUnAssignedDevices)
		setAssignedDevices(assignedDevices)
		
	}, [users,devices]);

	useEffect(() => {
		if(deploymentConfiguration.Deployment){
			setIsDeviceBasedAlert(deploymentConfiguration.Deployment.isDeviceBasedAlert)
		}
	}, [])

	useEffect(() => {

	}, [assignedDevices, unAssignedDevices])
	useEffect(() => {

	}, [assignedUsers, unAssignedUsers])

	const selectAllUser =(checkStatus,user)=>{
		if(checkStatus === true){
			
            let tempIds = (user.map((data)=>{
                return (data.id)
            }))
			setSelectedUsers([...tempIds])
        }else{
            setSelectedUsers([])
        }
	}

	const selectAllDevice =(checkStatus,device)=>{
		if(checkStatus === true){
            let tempIds = (device.map((data)=>{
                return (data.id)
            }))
			setSelectedDevices([...tempIds])
        }else{
            setSelectedDevices([])
        }
	}

	const filter = (values) =>{
	let tempValues = [];
		if(role && role.length > 0){
			values.map((value)=>{
				if(value.roleIds.includes(parseInt(role) ))
					tempValues.push(value)
			})
		}else{
			tempValues = values
		}
	// let updatedValues = []
	// 	if(search && search.length > 0){
	// 		tempValues.map((value)=>{
	// 			if(
	// 				value.firstName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
	// 				value.lastName.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
	// 			){
	// 				updatedValues.push(value)
	// 			}
	// 		})
	// 	}else{
			let updatedValues = tempValues
	//	}
		
		if (!role && ! search){
			tempValues = values;
		}
		return updatedValues
	}
	const selectUser = (userId) => {
		setSelectedUsers([...userId])
	}

	const selectDevice = (deviceId) => {
		setSelectedDevices([...deviceId])
	}

	const selectUserMobileView = (userId) => {
		let tempSelectedUsers = selectedUsers;
		let index = tempSelectedUsers.indexOf(userId)
		if (index > -1) {
			tempSelectedUsers.splice(index, 1);
		}else{
			tempSelectedUsers.push(userId)
		}
		setSelectedUsers([...tempSelectedUsers])
	}

	const selectDeviceMobileView = (deviceId) => {
		let tempSelectedDevices = selectedDevices;
		let index = tempSelectedDevices.indexOf(deviceId)
		if (index > -1) {
			tempSelectedDevices.splice(index, 1);
		}else{
			tempSelectedDevices.push(deviceId)
		}
		setSelectedDevices([...tempSelectedDevices])
	}


	const moveSelectedUsers = () => {
		setUnassinedUserSelectAllCheckBox(false)
		let tempAssignedValues = assignedUsers
		let tempUnAssignedValues = unAssignedUsers
		let tempSelectedUsers = selectedUsers;
		users.map((user) => {
			if (selectedUsers.includes(user.id) && tempUnAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
				tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === user.id), 1);
				tempAssignedValues.push({
					id:user.id,
					advisorId: user.advisorId,
					age: user.age,
					facilityId: user.facilityId,
					firstName: user.firstName,
					functionalRoleId: user.functionalRoleId,
					gender: user.gender,
					groupIds: user.groupIds,
					interests: user.interests,
					lastName: user.lastName,
					location: user.location,
					middleName: user.middleName,
					nickName: user.nickName,
					prefix: user.prefix,
					profilePicture: user.profilePicture,
					roleId: user.roleId,
					suffix: user.suffix,
					umsUserId: user.umsUserId,
					userName: user.userName,
					roleIds : user.roleIds
				})
				tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedUsers([...tempSelectedUsers])
		setAssignedUsers([...tempAssignedValues])
		setUnAssignedUsers([...tempUnAssignedValues])
	}

	const moveUnSelectedUsers = () => {
		setAssinedUserSelectAllCheckBox(false)
		let tempAssignedValues = assignedUsers
		let tempUnAssignedValues = unAssignedUsers
		let tempSelectedUsers = selectedUsers;
		users.map((user) => {
			if (selectedUsers.includes(user.id) && tempAssignedValues.map((data)=>{return data.id}).includes(user.id)) {
			    tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === user.id), 1);
				tempUnAssignedValues.push({
					id:user.id,
					advisorId: user.advisorId,
					age: user.age,
					facilityId: user.facilityId,
					firstName: user.firstName,
					functionalRoleId: user.functionalRoleId,
					gender: user.gender,
					groupIds: user.groupIds,
					interests: user.interests,
					lastName: user.lastName,
					location: user.location,
					middleName: user.middleName,
					nickName: user.nickName,
					prefix: user.prefix,
					profilePicture: user.profilePicture,
					roleId: user.roleId,
					suffix: user.suffix,
					umsUserId: user.umsUserId,
					userName: user.userName,
					roleIds : user.roleIds
				})
                tempSelectedUsers.splice(tempSelectedUsers.indexOf(user.id), 1);
			}
		})
		setSelectedUsers([...tempSelectedUsers])
		setAssignedUsers([...tempAssignedValues])
		setUnAssignedUsers([...tempUnAssignedValues])
	}

	const moveSelectedDevices = () => {
        setUnassinedDeviceSelectAllCheckBox(false)
        let tempAssignedValues = assignedDevices
        let tempUnAssignedValues = unAssignedDevices
        let tempSelectedDevices = selectedDevices;
        devices.map((device) => {
            if (selectedDevices.includes(device.id) && tempUnAssignedValues.map((data) => { return data.id }).includes(device.id)) {
                tempUnAssignedValues.splice(tempUnAssignedValues.findIndex(data => data.id === device.id), 1);
                tempAssignedValues.push(device)
                tempSelectedDevices.splice(tempSelectedDevices.indexOf(device.id), 1);
            }
        })
        setSelectedDevices([...tempSelectedDevices])
        setAssignedDevices([...tempAssignedValues])
        setUnAssignedDevices([...tempUnAssignedValues])
    }

	const moveUnSelectedDevices = () => {
        setAssinedDeviceSelectAllCheckBox(false)
        let tempAssignedValues = assignedDevices
        let tempUnAssignedValues = unAssignedDevices
        let tempSelectedDevices = selectedDevices;
        devices.map((device) => {
            if (selectedDevices.includes(device.id) && tempAssignedValues.map((data) => { return data.id }).includes(device.id)) {
                tempAssignedValues.splice(tempAssignedValues.findIndex(data => data.id === device.id), 1);
                tempUnAssignedValues.push(device)
                tempSelectedDevices.splice(tempSelectedDevices.indexOf(device.id), 1);
            }
        })
        setSelectedDevices([...tempSelectedDevices])
        setAssignedDevices([...tempAssignedValues])
        setUnAssignedDevices([...tempUnAssignedValues])
    }

	const createGroup = async (event) => {
		let id=[];
		assignedUsers.map((data)=>{
			id.push(data.id)
		})
		let deviceIds=[];
		assignedDevices.map((data)=>{
			deviceIds.push(data.id)
		})
		if (!functions.validateInput(groupName,setErrorMessage, "alphanumeric")) {
			return setGroupNameError(true)
		}
		let data = {
			"groupName": groupName,
			"userIds":id,
			"deviceIds" :deviceIds,
			"facilityId": localStorage.getItem("facilityId")
		}
		dispatch(setLoading(true))
		let response = null
		if (propsData.id) {
			data["id"] = propsData.id
			response = await API.putAPI(URL.groups, data)
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					// fetchGroup();
					props.onCancel()
					dispatch(setSnackData(snackData))
				} else if (response.result.status === "failure") {
	
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setLoading(false))
					dispatch(setSnackData(snackData))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down. Unable to update user.",
					snackVariant: "error"
				}
				dispatch(setLoading(false))
				dispatch(setSnackData(snackData))
			}
		}else{
			data["administratorId"] = localStorage.getItem("loginUserId")
			response = await API.postAPI(URL.groups, data)
			if (response.fetchStatus === "success") {
				if (response.result.status === "success") {
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "success"
					}
					// fetchGroup();
					props.onCancel()
					dispatch(setSnackData(snackData))
				} else if (response.result.status === "failure") {
	
					let snackData = {
						showSnack: true,
						snackMessage: response.result.message,
						snackVariant: "error"
					}
					dispatch(setSnackData(snackData))
					dispatch(setLoading(false))
				}
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: "Server down. Unable to update user.",
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
				dispatch(setLoading(false))
			}
		}
		
		
	}
	const validateGroupName=(event)=>{
		setGroupNameError(false)
		setErrorMessage("")
		let value = event.target.value;
		if (!functions.firstCharacterValidation(value)) {
			setErrorMessage("First letter cannot be space.")
			return setGroupNameError(true)
		}else{
			setGroupName(value)
		}
	}

	function navigateUsersCard() {
        setShowUsersCard(true)
        setShowDevicesCard(false)
    }
    function navigateDevicesCard() {
        setShowDevicesCard(true)
        setShowUsersCard(false)
    }
	return (
		<div className="add-group-container" data-testid="add-group-container-id">
			<div className="modal">
				<div className="modal-content-container add-group-modal">
					<div className="modal-content add-group-modal-content ">
						<div className="modal-title" data-testid="group-popup-title">
							{propsData.id ?
								"Update Group"
							:
								"Add Group"
							}
						</div>
						<div className='modal-inner-container'>
							<div className='response-container' data-testid="group-popup-error-message-ctr">{errorMessage}</div>
							<div className="group-name-container">
								<input type="text" className={("group-name-input ") + (groupNameError? " invalid-error " : null)} data-testid="add-group-name-input-box" id="add-group-name-input-box" placeholder='Group Name *' value={groupName} onChange={(event) => validateGroupName(event)} />
							</div>
							<div className={!showDevicesCard ?"group-filter-container" : "hide-role-filter"}>
								<div className="group-filter-container-title" data-testid="group-filter-title" id="group-filter-title">Role</div>
								<div className="group-filter-inner-container">
								<select disabled={showDevicesCard} value={role} data-testid="group-role-filer" id="group-role-filer" onChange={(e)=>{setRole(e.target.value)}}>
									<option value=''>All</option>
									{userTypes.map((type, index)=>
										<option key={index} value={type.id} data-testid="group-role-filter-data">{type.roleName}</option>
									)}
								</select>
									{/* <input type="text" value={search} className="group-filter-search" placeholder="Search" onChange={(e) => setSearch(e.target.value)}></input> */}
								</div>
							</div>


							<div className='select-card'>
									<button className={showUsersCard ? 'card-selected' : ''} onClick={navigateUsersCard} id='usersButton' data-testid='usersNav'>Users - {assignedUsers.length}</button>
									{isDeviceBasedAlert && <div className='align'><div class="vertical-line"></div></div>}
									{isDeviceBasedAlert &&<button className={showDevicesCard ? 'card-selected' : ''} onClick={navigateDevicesCard} id='groupsButton' data-testid='devicesNav'>Devices - {assignedDevices.length}</button>}

                           	</div>



							   {showUsersCard && <div className='user-list-select-container-list'>
								<div className='user-list-unselected-container-list' data-testid="unAssigned-user-card-ctr" id="unAssigned-user-card-ctr">
									<Card 
									 header="select"
									 type="add-users-group"
									 title="Users Available"
									 item={filter(unAssignedUsers)}
									 selectedData = {(data)=>selectUser(data)}
									 selectedDataMobile = {(data)=>{selectUserMobileView(data)}}
									 selectAll = {(checkStatus,data)=>{selectAllUser(checkStatus,data)}}
									 setSelectAllStatus={(checkStatus)=>{setUnassinedUserSelectAllCheckBox(checkStatus);setAssinedUserSelectAllCheckBox(false)}}
									 checkedStatus={unassignedUserSelectAllCheckBox}
									 selectionData={selectedUsers}
									 dataTestId={"unAssignedUserData"}
									 selectAllDataTestId={"UnAssignedUser-select-all"}
									 searchDataTestId={"unAssigned-search-filter-input-box"}
									/>
								</div>
								<div className="icon">
                                    <BsArrowRight onClick={ () => moveSelectedUsers()} data-testid="add-unAssigned-user"/>
                                    <BsArrowLeft onClick={() => moveUnSelectedUsers()} data-testid="remove-Assigned-user"/>
                                    
                                </div >

								<div className='user-list-selected-container-list' id="Assigned-user-card-ctr" data-testid="Assigned-user-card-ctr">
									<Card 
										 headers="deselect"
										 type="add-users-group"
										 title="Users In Group"
										 item={filter(assignedUsers)}
										 selectedData = {(data)=>selectUser(data)}
										 selectAll = {(checkStatus,data)=>{selectAllUser(checkStatus,data);}}
										 setSelectAllStatus={(checkStatus)=>{setAssinedUserSelectAllCheckBox(checkStatus);setUnassinedUserSelectAllCheckBox(false)}}
										 checkedStatus={assignedUserSelectAllCheckBox}
										 selectionData={selectedUsers}
										 dataTestId={"AssignedUserData"}
										 selectAllDataTestId={"AssignedUser-select-all"}
										 selectedDataMobile = {(data)=>{selectUserMobileView(data)}}
									/>
								</div>	
							</div>}


							{showDevicesCard && <div className='user-list-select-container-list'>
								<div className='user-list-unselected-container-list' data-testid="unAssigned-user-card-ctr" id="unAssigned-user-card-ctr">
									<Card 
									 header="select"
									 type="add-device"
									 title="Devices Available"
									 item={unAssignedDevices}
									 selectedData = {(data)=>selectDevice(data)}
									 selectedDataMobile = {(data)=>{selectDeviceMobileView(data)}}
									 selectAll = {(checkStatus,data)=>{selectAllDevice(checkStatus,data)}}
									 setSelectAllStatus={(checkStatus)=>{setUnassinedDeviceSelectAllCheckBox(checkStatus);setAssinedDeviceSelectAllCheckBox(false)}}
									 checkedStatus={unassignedDeviceSelectAllCheckBox}
									 selectionData={selectedDevices}
									 dataTestId={"unAssignedDeviceData"}
									 selectAllDataTestId={"UnAssignedDevice-select-all"}
									 searchDataTestId={"unAssigned-search-filter-input-box"}
									/>
								</div>
								<div className="icon">
                                    <BsArrowRight onClick={ moveSelectedDevices} data-testid="add-unAssigned-device"/>
                                    <BsArrowLeft onClick={moveUnSelectedDevices} data-testid="remove-Assigned-device"/>
                                    
                                </div >

								<div className='user-list-selected-container-list' id="Assigned-user-card-ctr" data-testid="Assigned-user-card-ctr">
									<Card 
										 headers="deselect"
										 type="add-device"
										 title="Devices In Group"
										 item={assignedDevices}
										 selectedData = {(data)=>selectDevice(data)}
										 selectionData={selectedDevices}
										 selectAll = {(checkStatus,data)=>{selectAllDevice(checkStatus,data);}}
										 setSelectAllStatus={(checkStatus)=>{setAssinedDeviceSelectAllCheckBox(checkStatus);setUnassinedDeviceSelectAllCheckBox(false)}}
										 checkedStatus={assignedDeviceSelectAllCheckBox}
										 dataTestId={"AssignedDeviceData"}
										 selectAllDataTestId={"AssignedDevice-select-all"}
										 selectedDataMobile = {(data)=>{selectDeviceMobileView(data)}}
									/>
								</div>	
							</div>}





							<div className="card-instruction">
								<div>Hold down</div>
								<div className="card-instruction-bold">&nbsp;CTRL&nbsp;</div>
								<div>, or </div>
								<div className="card-instruction-bold">&nbsp;<BiCommand/>&nbsp;</div>
								<div>on a Mac to select more than one.</div> 
							</div>
							<div className="popup-button-container">
								<button className="cancel-button " data-testid="add-group-popup-close-btn-id" onClick={(e) =>props.onCancel()}>Cancel</button>
								<div class="vertical_line" ></div>
								<button className="save-button" data-testid="add-group-popup-add-btn-id" onClick={()=>createGroup()} >
									{propsData.id ?
										"Update Group"
										:
										"Add Group"
									}
								</button>
							</div >
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default AddGroups;
