import React from "react";
import "../style/style.css"
function Confirmation(props) {
    const {
        deleteMessage,
        successFunc,
        cancelFunc,
        buttonValue,
        className
    } = props;
    return (
        <div className = {className ? "modal "+className : "modal" }>
            <div className="modal-content-container confirmation-modal" data-testid="delete-confirmation-modal">
                <div className="confirmation-message" data-testid="delete-confirmation-message">
                    { deleteMessage ? deleteMessage : "Are you sure want to continue ?" }
                </div>
                <div className="confirmation-button-container">
                    <button className="cancel-button" onClick={() => cancelFunc()}  data-testid="delete-confirmation-cancel-btn">Cancel</button>
                    <div className="vertical_line"></div>
                    <button className="save-button" onClick={() => successFunc()} data-testid="delete-confirmation-success-btn">{buttonValue ? buttonValue : "Delete"}</button>
                </div>     
            </div>
        </div>
    );
}

export default Confirmation;
