import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from "moment-timezone";
import { IoArrowBackOutline } from "react-icons/io5"
import ButtonField from '../../../common/component/ButtonField';
import DownloadFilePopUp from './DownloadFilePopUp';
import { setSnackData, setLoading } from '../../../redux/action/userAction';
import { setMusteringLocation} from '../../../redux/action/userAction';
import * as API from '../../../common/api/index.js';
import URL from "../../../common/api/constantURL";
import '../../Style/style.css';
import BreadCrumbIcon from '../../../assets/images/breadcrum.png';
import BreadCrumb from '../../../common/component/BreadCrumb';
import Table from '../../../common/component/Table'
import "./SingleEvent.css"
import downloadIcon from "../../../assets/svg/download.svg";

function SingleEvent({ eventData, showEventList}){

	const dispatch = useDispatch();
	const [alertDetail, setAlertDetail] = useState([])
	const [alertFullDetail, setAlertFullDetail] = useState({})
	var [checkInCount, setCheckInCount] = useState(0)
	var [safeCount, setSafeCount] = useState(0)
	var [medicalCount, setMedicalCount] = useState(0)
	var [absentCount, setAbsentCount] = useState(0)
	const [downLoadReportData, setDownLoadReportData] = useState([])
	const BreadCrumbArr = [
		{link:"/home/dashboard",display:"Dashboard",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon}/>,type:'img'},
		{link:"/home/reports/eventHistory",display:"Reports",type:"react"},
		{link:'',display:<img src={BreadCrumbIcon}/>,type:'img'},
		{link:"/home/reports/eventHistory",display:"Mustering Event History",type:"react"},
   ];
	useEffect(() => {
		fetchAlertDetail()
	}, []);
	const fetchAlertDetail = async () => {
		dispatch(setLoading(true))
		let url = URL.musteringHistoryDetail + "?alertId=" + eventData.id;
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let alertDetail = response.result.result.users
				let tempcheckInCount = 0;
				let tempsafeCount = 0;
				let tempabsentCount = 0;
				let tempmedicalCount = 0;
				alertDetail.map((user,index)=>{
					alertDetail[index].fullName = user.firstName + " " + user.lastName
					alertDetail[index].format = "DD MMM, YYYY hh:mm A"
					if (user.checkInTime){
						let dateObj = moment(user.checkInTime+".0000000Z", "YYYY-MM-DD HH:mm:ss.0000000Z");
						alertDetail[index].checkedInDateTime = moment.utc(dateObj).format("DD MMM, YYYY hh:mm A")
					}
					if (user.checkedIn) {
						tempcheckInCount++;
					}
					if (user.isSafe) {
						tempsafeCount++;
					}
					if (user.isAbsent) {
						tempabsentCount++;
					}
					if (user.needMedicalEmergency) {
						tempmedicalCount++;
					}
				})
				setCheckInCount(tempcheckInCount)
				setSafeCount(tempsafeCount)
				setAbsentCount(tempabsentCount)
				setMedicalCount(tempmedicalCount)
				setAlertDetail(alertDetail)
				setAlertFullDetail(response.result.result)
			} else {
				let snackData = {
					showSnack: true,
					snackMessage: response.result.message,
					snackVariant: "error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack: true,
				snackMessage: "Server down. Failed to fetch.",
				snackVariant: "error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const [downloadPopUp, setdownloadPopUp] = useState(false)
	return (
		<>
			<div className="specific-event-history-container">  
				<BreadCrumb  crumbs={BreadCrumbArr}  dataTestid="mustering-single-event-history-breadcrum"></BreadCrumb>
					<div className="top-content">
						<div className='specific-even-top-content-row-1'>
							<div className="specific-even-top-content-title">
							<p data-testid="single-event-title"> Results for: {eventData.eventName} on {eventData.alertDate} at {eventData.alertTime} </p>
							</div>
							<div className='alarm-triggered-details-ctr'>
								<span className='alarm-triggered-by-ctr'>{alertFullDetail.alertTriggeredBy && <span data-testid="alert-triggered-by-ctr">Alarm triggered by: <span  className='alarm-triggered-by-name-ctr'>{alertFullDetail.alertTriggeredBy}</span></span>}</span>
								<span className='alarm-cleared-by-ctr'>{alertFullDetail.alertClearedBy && <span data-testid="alert-cleared-by-ctr" >Alarm cleared by: <span className="alarm-cleared-by-name-ctr">{alertFullDetail.alertClearedBy}</span></span>}</span>
							</div>						
							<div className='download-report-btn-field'>
								<button className='download_btn' data-testid="download-report-button"  id="download-report-button"  onClick={(e)=>{setdownloadPopUp(true)}}>
									<span>Download Report</span>
									<img src={downloadIcon} alt="file-upload" className='download_icon'/>
								</button>
								{/* <ButtonField label="Download Report" /> */}
							</div>
						</div>		
					<div className='status-bar-conatiner'>
							<div className="display-flex current-result current-result-1">
								<div>Results</div>
							</div>
							<div className="display-flex current-result current-result-2"  >
								<div data-testid="check-in-status">Checked In ({checkInCount})</div>
							</div>
							<div className="report-line" ></div>
							<div className="display-flex current-result ">			
								<div data-testid="medical-status">Needed Medical ({medicalCount})</div>		
							</div>
							<div className="report-line" ></div>
							<div className="display-flex current-result ">			
								<div data-testid="absent-status">Absent ({absentCount})</div>		
							</div>
							<span className="back-to-history">
								<button onClick={() => showEventList()} data-testid="back-to-mustering-page" id="back-to-mustering-page"> <IoArrowBackOutline />Back to Mustering Event History</button>
							</span>
					</div>
					
				</div>  
				
				<div className="bottom-content"> 
					<div className="col col-100">
						<Table
							headers={[
								{ id: 'firstName', label: 'First Name',dataTestid:"firstName-field", width: 100, responsive: true, sortable : true },
								{ id: 'lastName', label: "Last Name",dataTestid:"lastName-field", width: 100, responsive: true , sortable : true },
								{ id: 'checkedInDateTime',dataTestid:"checkedInDateTime-field", label: "Checked In At", width: 100, responsive: true, sortable: true },
								{ id: 'checkedIn',dataTestid:"checkedIn-field", label: 'Checked in', type:'check_icon',  width: 70, justifyContent:"center",responsive: true },
								{ id: 'needMedicalEmergency',dataTestid:"needMedicalEmergency-field", label: 'Medical', width: 60, type: 'check_icon', justifyContent: "center", responsive: true },
								{ id: 'isAbsent',dataTestid:"isAbsent-field", label: 'Absent', type:'check_icon',justifyContent:"center", width: 90, responsive: true },
							]}
							data={alertDetail}
							defaultOrderBy={"checkedInDateTime"}
							defaultSortOrder={"asc"}
							singleEvent={true}
						/>
					</div>   
				</div>  
			</div>
			{downloadPopUp && <DownloadFilePopUp eventData={eventData} alertDetail={alertDetail} alertFullDetail={alertFullDetail} onCancel={(e)=>{setdownloadPopUp(false)}}/> }
		</>
	);
}
export default SingleEvent;
