const actiontypes = {
    SET_USER:"SET_USER",
    SET_USERS_SEARCH:"SET_USER_SEARCH",
    SET_USERS_FILTER_ROLE:" SET_USERS_FILTER_ROLE",
    SET_USERS_FILTER_GROUP:"SET_USERS_FILTER_GROUP",
    SET_USER_PAGINATION_DATA:"SET_USER_PAGINATION_DATA",
    SET_USER_PER_PAGE_DATA:"SET_USER_PER_PAGE_DATA",
    SET_GROUPS: "SET_GROUPS",
    LOGIN : 'LOGIN',
    USER_TYPE:'USER_TYPE',
    SET_POPUP:"SET_POPUP",
    SET_ACCOUNT_DATA: "SET_ACCOUNT_DATA",
    SET_FACILITY_DATA: "SET_FACILITY_DATA",
    SET_SPECIFIC_FACILITY_DETAIL:"SET_SPECIFIC_FACILITY_DETAIL",
    SET_FACILITY_SELECTION_FLAG: "SET_FACILITY_SELECTION_FLAG",
    SET_SNACK_DATA:"SET_SNACK_DATA",
    SET_ALERT_COLOR:"SET_ALERT_COLOR",
    SET_ALERT_ICON:"SET_ALERT_ICON",
    SET_SERVER_CONFIG:'SET_SERVER_CONFIG',
    SET_ACTIVE_MUSTERING_LIST:"SET_ACTIVE_MUSTERING_LIST",
    SET_CURRENT_MUSTERING_EVENT_USER_LIST:"SET_CURRENT_MUSTERING_EVENT_USER_LIST",
    SET_MUSTERING_LOCATION:"SET_MUSTERING_LOCATION",
    SET_ALARMS:"SET_ALARMS",
    SET_LOADING:"SET_LOADING",
    SET_SERVICE_ALLOCATION:"SET_SERVICE_ALLOCATION",
    SET_ROLES_DETAILS:"SET_ROLES_DETAILS",
    SET_ADVISOR_DETAILS:"SET_ADVISOR_DETAILS",
    SET_USER_PROFILE_DETAIL:"SET_USER_PROFILE_DETAIL",
    SET_USER_PERMISSION: "SET_USER_PERMISSION",
    SET_ALERT_EVENT_HISTORY: "SET_ALERT_EVENT_HISTORY",
    SET_DEVICES : "SET_DEVICES",
    SET_DEVICES_SEARCH :"SET_DEVICES_SEARCH",
    SET_DEPLOYEMENT_CONFIG : "SET_DEPLOYEMENT_CONFIG",
    SET_EVENTS : "SET_EVENTS"
}

export default actiontypes;
