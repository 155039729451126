import React, { useDebugValue, useEffect, useState, useRef } from 'react'
import BreadCrumbIcon from '../../assets/images/breadcrum.png';
import BreadCrumb from '../../common/component/BreadCrumb';
import '../Style/ApplicationManagement.css'
import ButtonField from '../../common/component/ButtonField';
import {useDispatch, useSelector} from 'react-redux';
import URL from "../../common/api/constantURL";
import { setSnackData, setLoading , setUser} from '../../redux/action/userAction';
import { setGroups } from '../../redux/action/groupAction';
import {setServiceAllocation} from '../../redux/action/serviceAllocation';
import * as API from '../../common/api/index.js';
import SearchabelDropDown from '../../common/component/SearchableDropDown';
import {defaultPreference, separatorPreference} from '../../common/variables/preference'
import {formatUserName} from '../../common/functions/userNameFormat';
import { validatePermission } from "../../common/functions/permissionFunctions"
function ApplicationManagement() {
	/*  var [search,setSearch] = useState('') */
	const users = useSelector(state => state.userReducer.users)
	const groups = useSelector(state => state.groupReducer.groups)
	const serviceAllocation = useSelector(state => state.serviceAllocationReducer.serviceAllocation);
	// const userList = useSelector(state => state.userReducer.users)
	const dispatch = useDispatch();
	const userPermissions = useSelector(state => state.userReducer.userPermissions)
    const [all,setAll] = useState(false);
	const [selectedService, setSelectedService] = useState([]);
	const [specificUser,setSpecificUser] = useState(false);
	const [specificGroup,setSpecificGroup] = useState(false);
	const [allUsers,setAllUsers] = useState(false);
	const [allGroups,setAllGroups] = useState(false);
	const [selectedUser,setSelectedUser] = useState("");
	const [userOption, setUserOption] = useState([]);
	const [selectedGroups,setSelectedGroups] = useState("");
	const [groupOption, setGroupOption] = useState([]);
	const [selectAllCheckbox,setSelectAllCheckBox] = useState(false);
    const [serviceError,setServiceError] = useState(false);
	const [allgroupUserError, setAllGroupUserError] = useState(false)
	const [userError,setUserError] = useState(false);
	const [groupError,setGroupError] = useState(false);

	const BreadCrumbArr = [
		{ link: "/home/dashboard", display: "Dashboard", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt="BreadCrumb" />, type: 'img' },
		{ link: "/home/system/server-configuration", display: "System", type: "react" },
		{ link: '', display: <img src={BreadCrumbIcon} alt="BreadCrumb" />, type: 'img' },
		// { link: "", display: "Application Management", type: "react" },
		{ link: "", display: "Service Allocation", type: "react" },
	];
	
	useEffect(()=>{
	dispatch(setLoading(true));
	fetchUser();
    fetchGroup();
	fetcServiceAllocation();
	},[])	

	const userOptionList  = (users) => {
		let usersTemp = [...users];
		usersTemp.sort((a, b) => {
			let a_fullname = formatUserName(a, defaultPreference, separatorPreference.Space);
			let b_fullname = formatUserName(b, defaultPreference, separatorPreference.Space);
            return a_fullname.localeCompare(b_fullname);
		});
		let useroption = usersTemp.map((u) => {
			return {'id':u.id, 'value': formatUserName(u, defaultPreference, separatorPreference.CommaSpace)}});
		setUserOption(useroption);
	};
	
	const groupOptionList = (group) => {
		let groupoption = group.map((g) => {return {'id':g.id, 'value':g.groupName}});
		setGroupOption(groupoption);
	};

	const fetchUser = async () => {
		let url = URL.usersByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.users
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		let userList = response.result.result	
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				userOptionList(userList);
				dispatch(setUser(userList));                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	const fetchGroup = async () => {
		let url = URL.groupsByFacility + "?facilityId=" + localStorage.getItem("facilityId");
		if (parseInt(localStorage.getItem("functionalRoleId")) <= 2) {
			url = URL.groups
		}
		let response = await API.getAPI(url)
		dispatch(setLoading(false))
		if(response.fetchStatus === "success"){
			if(response.result.status === "success"){
				let groupList = response.result.result
				for (let i = 0; i < groupList.length; i++) {
					groupList[i].userCount = groupList[i].userIds.length
					if (groupList[i].isEventGroup) {
						groupList.splice(i,1)
						i--
					}
				}
				groupOptionList(groupList)
				dispatch(setGroups(groupList))
			}else{
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		}else if(response.fetchStatus === "failure"){
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}

	const fetcServiceAllocation = async () => {
		let response = await API.getAPI(URL.serviceAllocation)
		 dispatch(setLoading(false))		
		if (response.fetchStatus === "success") {
			if (response.result.status === "success") {
				let ServiceList = response.result.result
				dispatch(setServiceAllocation(ServiceList))
			} else {
				let snackData = {
					showSnack:true,
					snackMessage:response.result.message,
					snackVariant:"error"
				}
				dispatch(setSnackData(snackData))
			}
		} else if (response.fetchStatus === "failure") {
			let snackData = {
				showSnack:true,
				snackMessage:"Server down. Failed to fetch.",
				snackVariant:"error"
			}
			dispatch(setSnackData(snackData))
		}
	}
	
useEffect(()=>{
	let temp=[];
	// if(selectedUser.length>0){
	if(selectedUser){
		serviceAllocation.map((item,i)=>
			item.allocatedUser.map((d)=>
		  		d == selectedUser ?temp.push(item.id):''
		    )
		)
	}
		temp.length>0 && temp.length == serviceAllocation.length ? setAll(true):setAll(false) 
		setSelectedService([...temp]);
},[selectedUser])

useEffect(()=>{
	let temp=[];
	// if(selectedGroups.length>0){
	if(selectedGroups){
		serviceAllocation.map((item,i)=>
			item.allocatedGroup.map((d)=>
				d == selectedGroups ?temp.push(item.id) :''
		    )
		)
	}
	temp.length>0 && temp.length == serviceAllocation.length ? setAll(true):setAll(false) 
	setSelectedService([...temp]);
},[selectedGroups]);

const selectService= (serviceId) => {
	setAll(false)
	let tempSelectedService = selectedService;
	let index = tempSelectedService.indexOf(serviceId)
	if (index > -1) {
		tempSelectedService.splice(index, 1);
	}else{
		tempSelectedService.push(serviceId)
	}	
	setSelectedService([...tempSelectedService])
	tempSelectedService.length == serviceAllocation.length ? setAll(true):setAll(false) 
}

const selectAllUser =(service,checkStatus)=>{
    if(checkStatus === true){
        setSelectedService([...service])
    }else{
        setSelectedService([])
        }
    }
const formSubmitHandler= async()=>{
       
        let allUsersData = {
			"allUser":1,
			"featureIds":selectedService,
			"facilityId": localStorage.getItem("facilityId"),
		}
	   let allGroupsData = {
		   "allGroup":1,
		   "featureIds":selectedService,
		   "facilityId": localStorage.getItem("facilityId"),
	   }
	   let specificUserData = {
		   "userId":selectedUser,
		   "featureIds":selectedService,
		   "facilityId": localStorage.getItem("facilityId"),
	   }
	   let specificGroupData = {
		   "groupId":selectedGroups,
		   "featureIds":selectedService,
		   "facilityId": localStorage.getItem("facilityId"),
	   }
	   let data;
	   if(allUsers){
		   data = allUsersData
	   }
	   else if(allGroups){
		   data = allGroupsData
	   }
	   else if(specificUser){ 
		   data = specificUserData
	   }
	   else if(specificGroup){
		
		   data = specificGroupData
	   }
	   else{
		return setAllGroupUserError(true)
	   }
 
	   let response = null;
	   if(selectedService == '' ){
		return setServiceError(true)
	   }
	   else if(specificGroup && selectedGroups == '' ){
		return setGroupError(true)
	   }
	   else if(specificUser && selectedUser == ''){
		return setUserError(true)
	   }
	   else{
		dispatch(setLoading(true))
	   response = await API.postAPI(URL.serviceAllocation, data)
	   dispatch(setLoading(false))
	   if(response.fetchStatus === "success"){
		   if(response.result.status === "success"){
		   let snackData = {
			   showSnack:true,
			   snackMessage:response.result.message,
			   snackVariant:"success"
		    }
			fetcServiceAllocation();
		   dispatch(setSnackData(snackData))
		} 
		else if(response.result.status  === "failure" && response.result.result === null){
			let snackData = {
				showSnack:true,
				snackMessage:response.result.message,
				snackVariant:"error"
			 }
			dispatch(setSnackData(snackData))
		}
	   }else  if(response.fetchStatus === "failure"){
		   let snackData = {
			   showSnack:true,
			   snackMessage:"Server down. Unable to update Services",
			   snackVariant:"error"
		   }
		   dispatch(setSnackData(snackData))
	   }
	   setServiceError(false)
	   setSelectedService([])
	   setAllUsers(false);
	   setAllGroups(false)
	   setSpecificUser(false)
	   setSpecificGroup(false)
	   setAll(false)
	   setSelectedUser('')
	   setSelectedGroups('')
	   setUserError(false)
	   setGroupError(false)
	 } 
	}

const userOnChange = (id) =>{
	setSelectedGroups('');
	setSelectedUser(id);
	setUserError(false);
	setGroupError(false);

}

const groupOnChange = (id) =>{
	setSelectedUser('');
	setSelectedGroups(id);
	setUserError(false);
	setGroupError(false);
}

	return (
		<div data-testid="application-management-container" className='application-management-container' > 
			<BreadCrumb crumbs={BreadCrumbArr} dataTestid={"bread-crums-list"}></BreadCrumb>
			<div className='list-page-title-div'>
				<div className="list-page-title  page-title" data-testid="application-management-title">Service Allocation
				{serviceError?
						<span className="service-error" data-testid="service-error">Need to select atleast one service</span>:
						userError?
							<span className="service-error" data-testid="user-error">Need to select User</span>:
						groupError?
						<span className="service-error" data-testid="group-error">Need to select Group</span>: 
						allgroupUserError ?
						<span className="service-error" data-testid="group-error">Need to select atleast one user or group.</span>:  ""

					}
					{/* <span className="service-error" data-testid="service-error">
						{	serviceError ?
								"Need to select atleast one service":
								userError ?
									"Need to select User" :
									groupError?
										"Need to select Group":""
						}
					</span> */}
				</div>	
			</div>
			
			<div className='application-management-section'>
				<div className='service-allocation-options-container' >
					<div className='service-allocation-option-container'>
						<label for="User Is Checked In" data-testid="allUser-label">All Users</label>
						<input type="checkbox"  
						  checked={allUsers?true:false}
						  onClick={
							  (e)=>{
								setSelectedService([])
								setAllUsers(e.target.checked)
								setSpecificGroup(false)
								setSpecificUser(false)
								setSelectedUser('')
								setSelectedGroups('')
								setAllGroups(false)
								setAll(false)
								setUserError(false)
								setGroupError(false)
								setServiceError(false)
								setAllGroupUserError(false)

							
							  }
							}
							data-testid="allUsers-checkbox"
							id="allUsers-checkbox"
                         />
					</div>
					<div className='service-allocation-option-container'>
						<label for="User Is Checked In" data-testid="allGroups-label">All Groups</label>
						<input type="checkbox"  
						checked={allGroups?true:false}
						onClick={
							(e)=>{
							  setSelectedService([])
								setAllGroups(e.target.checked)
								setSpecificGroup(false)
								setSpecificUser(false)
								setSelectedUser('')
								setSelectedGroups('')
								setAllUsers(false)
								setAll(false)	
								setUserError(false)
								setGroupError(false)
								setServiceError(false)	
								setAllGroupUserError(false)				
							}
						}
						data-testid="allGroups-checkbox"
						id="allGroups-checkbox"
						/>
					</div>
					<div className='service-allocation-option-container'>
						<label for="User Is Checked In"  data-testid="specificUser-label">Specific User</label>
						<input type="checkbox" value="SpecifiedUser" 
						checked={specificUser?true:false}
						onChange={
							()=>{
								setSpecificUser(!specificUser)
								setSelectedGroups('')
								setSpecificGroup(false)
								setAllUsers(false)
								setAllGroups(false)
								setSelectedService([])
								setAll(false)
								setUserError(false)
								setGroupError(false)
								setServiceError(false)
								setAllGroupUserError(false)
							}
						}
						data-testid="specificUsers-checkbox"
						id="specificUsers-checkbox"
						/>
					</div>
					<div className='service-allocation-option-container'>
						<label for="User Is Checked In" data-testid="specificGroup-label">Specific Group</label>
						<input type="checkbox" value="SpecifiedGroup" 
						checked={specificGroup?true:false}
						onChange={
							()=>{
							setSelectedUser('')
							setSpecificGroup(!specificGroup)
							setSpecificUser(false)
							setAllUsers(false)
							setAllGroups(false)
							setSelectedService([])
							setAll(false)
							setUserError(false)
							setGroupError(false)
							setServiceError(false)
							setAllGroupUserError(false)
						}
						}
						data-testid="specificGroups-checkbox"
						id="specificGroups-checkbox"
						/>
					</div>
				</div>
				{specificUser || specificGroup? 
				<div className="user-group-filter">
					<div className='selection-filter'>
						{(() => {
							if(specificUser){
								let props = {
									"optionList":userOption,
									"placeholder":"Select User",
									"onChangeMethod":userOnChange,
									"selectedOption":selectedUser,
								};
								return users && <SearchabelDropDown {...props}/>
							}
							else if(specificGroup){
								let props = {
									"optionList":groupOption,
									"placeholder":"Select Group",
									"onChangeMethod":groupOnChange,
									"selectedOption":selectedGroups,
								};
								return groups && <SearchabelDropDown {...props} />
							}
							else{
								return ""
							}
						})()}
					</div>
				</div>
				:''}
				<div className='row-3'>
					<div className='row-3-1'>
						<div>
							<h4 data-testid="services">Services</h4>
						</div>

						<div>
							<label for="User Is Checked In" data-testid="selectAll">Select All</label>
							<input type="checkbox"  
							 checked={all}
							  onChange={(e)=>
								{	
									setAll(e.target.checked)	
									selectAllUser(serviceAllocation.map((d)=>d.id),e.target.checked)				
									setServiceError(false)
									setAllGroupUserError(false)
									
                                    
								}}
								data-testid="selectAllCheckbox"
								id="selectAllCheckbox"
							/>
						</div>
					</div>
					<div className='row-3-2'>
						{serviceAllocation.map(service => {
							return <div className='checkbox-container' data-testid="feature-list" key={service.id}>
								<label 
									for={service.featureName} 
									onClick={() => selectService(service.id)} data-testid={service.featureName}>
									{service.featureName}
								</label>
								<input 
									type="checkbox" className="primary-checkbox" value={service.id}  
									checked={ selectedService.includes(service.id) ? true : false} 	
									onChange={() => {
										selectService(service.id) 
										setServiceError(false)
										setAllGroupUserError(false)
										
									}}
									data-testid={service.featureName+"checkbox"}
									id={service.featureName+"checkbox"}
									/>
							</div>
						})}
					</div>
				</div>

			</div>
			{validatePermission(userPermissions, "UPDATE_SERVICE_ALLOC") ? <div className='application-management-btn-field' data-testid="application-management-save-btn">
				{/* <ButtonField label="Cancel" /> */}
				<ButtonField type = "submit" label="Save" id="save-btn" dataTestid="save-btn"onClick={()=>formSubmitHandler()}/>
			</div>:null}
		</div>
	)
}

export default ApplicationManagement
