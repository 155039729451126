import { useRef, useEffect } from 'react';
import '../style/SelectBox.css';
import dropdown_arrow from '../../assets/svg/dropdown_arrow.svg';
function SelectBox(props) {
	const ref = useRef();

	useEffect(() => {
		const handleClickOutSide = (e) => {
			if (props.trigger && ref.current && !ref.current.contains(e.target)) {
				props.setTrigger(false)
			}
		};
		document.addEventListener("click", handleClickOutSide);
		return () => {
			document.removeEventListener("click", handleClickOutSide);
		}
	}, [props.trigger])


	return (
		<div className="dropdown" data-testid={props.dataTestid}>
			<div className={!props.valid ? "dropdown-btn validDropdown" : "dropdown-btn inValidDropdown"}
				onClick={() => props.setTrigger(!props.trigger)} data-testid="custom-select" id="custom-select">
				<span>
					{props.selectItem ?
						props.selectItem.hexValue ?
							<div className="selected-item">
								{/* <img src={props.selectItem.hexValue}  className="circle" style={{backgroundColor:props.selectItem.hexValue}} /> */}
								<div style={{ backgroundColor: props.selectItem.hexValue }} className="selected-color-box"></div>
								<span className="inner-padding" data-testid="selected-color" id="selected-color">
									{props.selectItem.name}
								</span>
							</div>
							:
							<div className="selected-item">
								<img src={props.selectItem.url} className="select-box-icon" />
								<span className="inner-padding" data-testid="selected-icon" id="selected-icon">
									{props.selectItem.name}
								</span>
							</div>
						:
						<span className={props.valid ? 'invalidSelectItem' : null}>Select</span>
					}
				</span>
				<span className="dropdown-arrow">
					<img src={dropdown_arrow} className={!props.trigger ? "drop-down-arrow" : "drop-down-arrow-rotation"} />
				</span>
			</div>
			{props.trigger ?
				<div className="dropdown-content" ref={ref} data-testid="options">
					{props.option ? props.option.map(((d, index) =>
						<div className="dropdown-item" key={index} onClick={(e) => {
							props.selectedItem(d)
							props.setTrigger(false)
							props.setValid(false)
						}} data-testid="select" id="custom-select-drop-down-item">
							{props.type === 'Icon' ?
								<img src={d.url} className="select-box-icon" />
								:
								props.type === 'Color' ?
									<span className="circle" style={{ backgroundColor: d.hexValue }}></span>
									:
									null
							}
							<span className="select-box-value" data-testid="select-box-value" id="select-box-value">
								{d.name}
							</span>
						</div>
					)) : null}
				</div>
				:
				null
			}
		</div>
	)
}
export default SelectBox;